import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { FalseLiteral } from 'typescript';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { HttpApiResponse } from '../../../../../model/DataTablesResponse';
import { categorie, etablissement } from '../../../../../model/db';
import { carte, concerner } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';
import Swal from "sweetalert2";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { datatableFr } from '../../../../../helpers/validators/datatable-fr';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-enroler-carte',
  templateUrl: './enroler-carte.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./enroler-carte.component.scss']
})
export class EnrolerCarteComponent extends Translatable implements OnInit,OnDestroy{
  endPOint: string = ""
  public concerner:concerner = new concerner();
  public concernerList:concerner[] = [];
  documents: any [] = [];
  matricule: string;
  numero_serie: string;
  etablissementId: number | string;
  public carteArray :carte[] = [] ;
  public etablissements:etablissement [] = [];
  superAdmin: number;
  currentEtabId: number | string;
  submitted: boolean;
  searchCarteActive: boolean;
  searchNumSerieSubmitted: boolean = false;
  situationCarteUpdated: number;
  requiredError: boolean = false;
  filename: string = "";
  dtOptionsToInsert: DataTables.Settings = {};
  dtOptionsError: DataTables.Settings = {};
  validatedExcel: boolean =  false
  isChecked: boolean  = false;

  readyToInsertList: any[] = []
  haveErrorList: any[];
  responseRecap: any = {};
  dtTriggerInsert: Subject<any> = new Subject<any>();
  dtTriggerError: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  isValid: boolean = false;

  numeroSerieExistInEtab: boolean = false;
  step2Carte: boolean = false;

  
  niveau_id: any;
  dtTriggerCheck: Subject<any> = new Subject<any>();
  dtTriggerRecap: Subject<any> = new Subject<any>();

  public categories: categorie [] = [];
  beneficiaireListToCheck: any[] = []
  etablissmentIdModal: any;
  categorieId: any;
  globalCheked: boolean = false;
  beneficiaireSelected: any = []
  activeRecpToEnroler: boolean = false;
  









  etabId:any=''

  filtre_etablissement_id:any='';
  tabBeneficiaireCheck : any = [];

  filtreBase:boolean=false
  filtreBaseParametres:any=''
  search:any=""
  filtreSelect:any= 10;
  tri : any= "";
  order : any= "";
  child : any= "";

  searchInput : any= "";
  public isCheckedElmBeneficiaire : boolean;

  searchCol : any= "";

  public searchColumn: string;
  public column: string;
  public childCol: string;
  public paginateListe : any = [];
  public nextPage : any = [];
  public prevPage : any = [];
  public lastPage : any = [];
  public firstPage : any = [];
  public currentPage : any = [];
  public total : any = [];
  public path : any = [];
  public lastNumPage : any = [];
  public infoData : any = [];
  public cPage_less_2 : number;
  public path_cPage_less_2 : any;
  public cPage_less_1 : number;
  public path_cPage_less_1 : any;
  public cPage_more_1 : number;
  public path_cPage_more_1 : any;
  public cPage_more_2 : number;  
  public path_cPage_more_2 : any;
  submitFiltre: boolean = false;
  activeEtabId: number;
  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private auth: AuthServiceService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,  

  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority(["GCA","DCA","AFC","ACD","ENC"]);
  

    this.dtOptions[0] = {
      pagingType: 'simple',
      language: datatableFr,
      destroy:true,
      order: false,
      lenghtMenu: [10]
      
      
    };
    this.dtOptions[1] = this.dtOptionsError = {
      pagingType: 'full_numbers',
      language: datatableFr,
      destroy:true
    };










    let userinfo = this.auth.account();
       
        this.superAdmin = userinfo.info.admin; 
        if(this.superAdmin != 1) {
          this.etablissementId = userinfo.info.etablissement_id;
          this.activeEtabId = userinfo.info.etablissement_id;
        }else {
          this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);

        }

        this.endPOint = environment.pension_concerners+"/getConcernneesWithCarteNoCompte?page=1";
         
    
        
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerInsert.unsubscribe();
    this.dtTriggerError.unsubscribe();

  }
  async getBeneficiaireByMatricule() {
    this.submitted = false;
    this.requiredError = false;
    if(!this.currentEtabId){
      this.requiredError = true;
      return
    }
    this.requiredError = false;
    this.searchCarteActive = false;
    let response: any = await this.http.get<HttpApiResponse>(environment.pension_concerners + "?page=1&where=concernees.matricule|e|"+this.matricule+",concernees.etablissement_id|e|"+this.currentEtabId,valuesys.httpAuthOptions()).toPromise();
    this.concernerList = response.data["data"]
    if(this.concernerList.length != 0) {
      this.concerner = response.data["data"][0];
      this.submitted = true
      return
    }
    this.submitted = true
  }
  async searchCarte() {
    // this.submitted = false;
    this.step2Carte = false;
    this.numeroSerieExistInEtab = false;
    this.searchNumSerieSubmitted = false;
    
    this.carteArray = [];

    const responseNumSerie: any =await this.http.get(environment.pension_cartes+"?page=1&where=carte.numero_serie|e|"+this.numero_serie,valuesys.httpAuthOptions()).toPromise();
    if(responseNumSerie.code == 200) {
      if(responseNumSerie.data.data.length == 0) {
       
        this.numeroSerieExistInEtab = false;
        this.searchNumSerieSubmitted = true

        return false
      }else {
        this.step2Carte = true;
        this.numeroSerieExistInEtab = true;
        const response: any =await this.http.get(environment.pension_cartes+"?page=1&where=carte.active|e|0,carte.numero_serie|e|"+this.numero_serie+",carte.etablissement_id|e|"+this.etablissementId,valuesys.httpAuthOptions()).toPromise();
        if(response.code == 200) this.carteArray = response.data.data
        console.log(this.carteArray,"crte array")
        this.searchNumSerieSubmitted = true;
      }
    }









  }


  activePanelSearchCarte() {
    this.searchCarteActive = true;
    this.etablissementId = this.concerner.etablissement_id;  
  }
  situationCarte(situation: number): string {
    switch (situation) {
      case 0:
        return this.__('carte.situation_0');
      case 1:
        return this.__('carte.situation_1');
      case 2:
        return this.__('carte.situation_2');
      case 3:
        return this.__('carte.situation_3');
      case 4:
          return this.__('carte.situation_4');
      default:
        break;
    }
  }
  async confirmAffectation(num_serie)
    {
        Swal.fire({
            title: this.__('msg.confirm')+" de vouloir" ,
            text: "Affecter la carte n° "+ num_serie +" au bénéficiaire : " + this.concerner.prenom + " "+ this.concerner.nom + " ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons : true,
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler"

        }).then(async (result) => {

            if (result.value) {
                let sup = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/affectation" , {"concerne_id":this.concerner.concernees_id, "numero_serie":num_serie},valuesys.httpAuthOptions()).toPromise();
                
                if(sup.code == 201){
                  Swal.fire({
                    icon: 'success',
                    title: "Donnée enregistrée",
                    confirmButtonColor: '#44A1A0',
                    text: sup.msg,
                  }).then((resultSuccess) => {
                      if(resultSuccess)
                      {
                        this.resetVariable();
                          // this.router.navigateByUrl(['/dashboard/BNF/plateforme/concernee/affectation-carte'],{state: {tabIndexActive: 1}});
                      }
                  })
                  // let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID ?page=1",valuesys.httpAuthOptions()).toPromise();
                  // if(response.code == 200) {
                  //   this.concerner = response.data["data"][0];
                    
                  // }
                 
                }else {
                    this.toastr.error(sup.data,this.__("msg.error"));
                }
            }
        })
    }
    goToCarte() {
      this.router.navigateByUrl('/dashboard/BNF/plateforme/concernee/affectation-carte',{state: {idBeneficiaire: this.concerner.concernees_id,tabIndexActive: 1}});
    }
    resetVariable() {
      this.searchCarteActive = false;
      this.searchNumSerieSubmitted = false;
      this.matricule = "";
      this.concerner = new concerner()
      this.concernerList = [];
      this.submitted = false;
      this.numero_serie = ""
      this.etablissementId = "";
      if(this.superAdmin == 1) this.currentEtabId = '';
    }
    async retirerCarte() {
      Swal.fire({
        title: this.__('msg.confirm'),
        text: "Retirer la carte?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: "Retirer la carte",
        cancelButtonText: "Annuler"
      }).then(async (result) => {
        if (result.value) {
            let res =  await this.http.post<any>( environment.pension_retirer_carte,{
                numero_serie: this.carteArray[0].numero_serie
            },valuesys.httpAuthOptions()).toPromise();
              //--success
              if(res.code === 200){
                this.situationCarteUpdated = 0;
                // this.searchNumSerieSubmitted = false;
                (this.carteArray[0] as any).affected = 0
                console.log(this.carteArray,'retire')
                this.toastr.success(res.msg,this.__("msg.success")) ;
              //--Warning : Donnée invalide, Session expiré
              }else if(res.code === 400){
                this.toastr.warning(res.msg,this.__("msg.warning")) ;
              }
              //--error
              else {
                this.toastr.error(res.data,this.__("msg.error")) ;
              }
    
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: "Action aborter!",
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.canceled_delete'),
            }
          )
        }
      })
  }

  // import 
  openModalImportExcel(template_3:TemplateRef<any>) {
    console.log("xxxx")
    
    this.modalService.open(template_3, { size: 'xl', backdrop:"static"  });
  } 
  handleInputChange(e,modelExcel: HTMLTemplateElement) {
    this.resetExcel();
    this.documents = [];
    this.filename = '';
    this.isValid = false;
    this.validateHeaderexcel(e,modelExcel)
    console.log(e);
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }

    this.filename = files[0].name;
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
    
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    let d :any = {};
    console.log("reader",reader)
    d.name = reader.result
    this.documents[0] = d
    console.log(this.documents)
    //this.partenaire.logo = reader.result;
  }

  close(){
  
    this.modalService.dismissAll();
    this.resetExcel();
    this.filename = '';
    this.documents = [];
    this.tabBeneficiaireCheck = [];
    this.beneficiaireListToCheck = [];
    this.submitFiltre = false;
    if(this.superAdmin  == 1) this.etablissmentIdModal = undefined;
    this.categorieId = undefined;
   
  }

  importExcel(documentFile: HTMLInputElement) {
     
    documentFile.click()
  }
  
  async  getRacpExcel() {
    this.resetExcel();

    let response = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/readingFileAffectation", {fileXls:this.documents[0].name},valuesys.httpAuthOptions()).toPromise();
    
    if(response.code == 200) {
      this.responseRecap = response.data as any
      this.validatedExcel = true;

      this.readyToInsertList = response.data.ready_to_insert;
      this.dtTriggerInsert.next();
      this.haveErrorList = response.data.error;
      this.dtTriggerError.next();
      console.log(this.responseRecap,"respons reacpitulatif", this.readyToInsertList, this.haveErrorList)

    }else {
      this.toastr.error(response.msg,this.__("msg.error"));

    }
    console.log(response,"documetn")
  }

  async  submitAffectationExcel() {

    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text: "Affecter le(s) "+this.readyToInsertList.length+" carte(s)  ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/readingFileAffectationInsert ", {fileXls:this.documents[0].name},valuesys.httpAuthOptions()).toPromise();
          
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: "Afféctation terminée avec succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.resetExcel()
                  this.documents = [];
                  this.filename = '';

                  this.close();
                }
            })  
          }else {
              this.toastr.error(response.msg,this.__("msg.error"));
          }
      }
  })
  }



  resetExcel() {
    this.readyToInsertList = [];
    this.haveErrorList = [];
    this.validatedExcel = false;
  }
  validateHeaderexcel(evt: any,modelExcel) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];



      /* save data */
      const dataExcel:any = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      const headers: any[] = dataExcel[0] as [];
      console.log(headers,'headeres')

      if(headers.length != 2 || headers[0] != 'matricule' ||  headers[1] != 'numero_serie') {
        Swal.fire({
          icon: 'error',
          title: 'Désolé...',
          text: 'Respecter le format du fichier excel \n[matricule|numero_serie]',
          confirmButtonColor: '#44A1A0',
        })
        this.filename = ''
        this.documents = [];
        this.isValid = false;
        return
      }

    };
    this.isValid = true;
    reader.readAsBinaryString(target.files[0]);
  }

  async activeAndDesactiveCarete(num_serie: number,state: number) {
    

    console.log(this.etablissementId,"xxxe tab id")
    this.submitted = true;
    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text:  state == 0 ? 'Activer la carte n° '+num_serie: 'Désactiver'+" la carte n° "+num_serie,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<any>(environment.pension_desactiv_carte,{
          "numero_serie": num_serie
        },valuesys.httpAuthOptions()).toPromise();          
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: state == 0 ? 'Activation': 'Désactivation'+"Désactivation avec succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.carteArray = [];
                  this.etablissementId = Number();
                  this.numero_serie = '';
                }
            })  
          }else {
            
            Swal.fire(
              {
                icon: 'error',
                title: "Erreur!",
                confirmButtonColor: '#44A1A0',
                text: response.data
              }
            )
              // this.toastr.error(response.data,this.__("msg.error"));
          }
      }
  })
  }


  async enrolerCarte(num_serie: string)
  {
      const path = environment.pension_cartes+'/enrolerCarte';
      Swal.fire({
          title: this.__('msg.confirm'),
          text: `Vouloir enroler  la carte n° ${num_serie}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#44A1A0',
          cancelButtonColor: '#FF7467',
          reverseButtons : true,
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler"

      }).then(async (result) => {



       

        let dataEnroler = [
          {
            concerne_id: this.carteArray[0].concerne_id,
            numero_serie: this.carteArray[0].numero_serie,
            etablissement_id: this.carteArray[0].etablissement_id,
            date_demande: new Date().toISOString().split('T')[0],
            date_enrolement: new Date().toISOString().split('T')[0]
          }
        ]
        
        



          if (result.value) {
            let sup = await this.http.post<HttpApiResponse>(environment.pension_cartes+"/planifier_enrolement",{demandes: dataEnroler},valuesys.httpAuthOptions()).toPromise();
            if(sup.code == 200 || sup.code == 201){
                  Swal.fire({
                    icon: 'success',
                    title: "Donnée enregistrée",
                    confirmButtonColor: '#44A1A0',
                    text: sup.msg,
                  }).then((resultSuccess) => {
                      if(resultSuccess)
                      {
                        this.resetVariable();
                          //this.router.navigate(['/dashboard/BNF/plateforme/carte_concerner/affectation']);
                      }
                  })
                
               
              }else {
                  this.toastr.error(sup.data,this.__("msg.error"));
              }
          }
      })
  }

  async openModalEnrolementMultiple(element: ElementRef) {
      this.tabBeneficiaireCheck = [];
      this.beneficiaireListToCheck = [];
     
      this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
      
      // this.categories = await this.helper.getSelectList(environment.pension_categories,['name']);
      let userinfo = this.auth.account()
      this.superAdmin = userinfo.info.admin; 
      if(this.superAdmin == 0) {
        this.etablissmentIdModal  = userinfo.info.etablissement_id; 
        let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.etablissmentIdModal, valuesys.httpAuthOptions()).toPromise();
        if(res.code == 200) {
            this.categories = res.data
        }
      }
    this.modalService.open(element, { windowClass:'mymodal-xl', backdrop:"static" });

    //this.modalService.open(element, { windowClass:'enroul_modal-xl', size: 'xl',backdrop:"static" });

  }

  async getResultFiltred() {
    this.submitFiltre = true;
    console.log(this.etablissmentIdModal,this.categorieId,"!etablissmentIdModal && !categorieId",!this.etablissmentIdModal && !this.categorieId)


    // stop here if form is invalid
    if (!this.etablissmentIdModal  || !this.categorieId ) {
        return;
    }
    
   
    let filtre_etab = '';
    if(this.etablissmentIdModal != '' && this.etablissmentIdModal != undefined){
      filtre_etab = ",concernees.etablissement_id|e|" + this.etablissmentIdModal ;
    } 
    let filtre_categorie = '';
    if(this.categorieId != '' && this.categorieId != undefined){
      filtre_categorie = ",concernees.categorie_concerne_id|e|" + this.categorieId ;
    } 

    let endPOintFiltre = environment.pension_concerners+"/getConcernneesWithCarteNoCompte?page=1&where=1|e|1" +filtre_etab +filtre_categorie ;
    this.getUrlDatatable(endPOintFiltre)
  }



  goToEnrolement() {
    this.activeRecpToEnroler = true;
    console.log("xxxx",this.activeRecpToEnroler)

    this.beneficiaireSelected = this.beneficiaireListToCheck.filter(val => val.isSelected == true);
    this.dtTriggerRecap.next()

  }

  selectAndDiselectItem(e,item) {
    // if(e.target.checked) {
    //   this.beneficiaireListToCheck = this.beneficiaireListToCheck.map(function(val){
    //     if(val.matricule == item.matricule) {
    //       return {
    //         ...item,
    //         isSelected: false
    //       }
    //     }
    //     return item
    //   })      
    // }else {
    //   this.beneficiaireListToCheck = this.beneficiaireListToCheck.map(function(val){
    //     if(val.matricule == item.matricule) {
    //       return {
    //         ...item,
    //         isSelected: true
    //       }
    //     }
    //     return item
    //   })    }
    // console.log(e.target.checked,"xxxxx",item,this.beneficiaireListToCheck)
  }

  async submitEnrolementMultiple() {
    // const path = environment.pension_cartes+'/enrolerCarte';
      Swal.fire({
          title: this.__('msg.confirm'),
          text: `Vouloir enroler  les cartes?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#44A1A0',
          cancelButtonColor: '#FF7467',
          reverseButtons : true,
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler"

      }).then(async (result) => {

          if (result.value) {
              const that = this
              let dataToenrole = this.tabBeneficiaireCheck.map(function(item){
                return {
                  concerne_id: item.concernees_id,
                  numero_serie: item.carte_numero_serie,
                  etablissement_id: that.etablissmentIdModal,
                  date_demande: new Date().toISOString().split('T')[0],
                  date_enrolement: new Date().toISOString().split('T')[0]
                }
              })
              let sup = await this.http.post<HttpApiResponse>(environment.pension_cartes+"/planifier_enrolement",{demandes: dataToenrole},valuesys.httpAuthOptions()).toPromise();
              if(sup.code == 201){
                  Swal.fire({
                    icon: 'success',
                    title: "Donnée enregistrée",
                    confirmButtonColor: '#44A1A0',
                    text: sup.msg,
                  }).then((resultSuccess) => {
                      if(resultSuccess)
                      {
                        this.submitted = false;
                        this.close();
                          //this.router.navigate(['/dashboard/BNF/plateforme/carte_concerner/affectation']);
                      }
                  })
                
               
              }else {
                  this.toastr.error(sup.data,this.__("msg.error"));
              }
          }
      })
  }

  async getSearchInput(){
    this.getUrlDatatable(this.endPOint)
  }

  async getFiltreSelect(){
    this.getUrlDatatable(this.endPOint)
  }

  async getUrlDatatable(url,col="", refElement?: any, child?: any, searchMulti?:any){
 
    if(refElement){
     this.searchColumn = refElement.value
     this.column = col
     this.childCol = child
    }
     if(searchMulti){
        if(this.superAdmin==1){
          //this.search = "&where=type_etablissement.id|e|"+ this.filtre_type_etablissement_id ;
          if(this.filtre_etablissement_id!="")
          this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
        }

      this.filtreBase=true

     }else{
        console.log("COL :" +col)
        console.log("Search column :" +this.searchColumn)
        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            if(this.superAdmin==1)
            this.search = "&where="+this.column+"|l|"+this.searchColumn;
            else
            this.search = ","+this.column+"|l|"+this.searchColumn;
          }else if(this.searchColumn == "") {
            this.search = "";
          }
        }else{

          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
            }else if(this.searchColumn == "") {
              this.searchCol = "";
            }
          }else{
            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.searchCol = "&where_or=concernees.matricule |l|"+this.searchInput+",concernees.nom|l|"+this.searchInput+",concernees.prenom|l|"+this.searchInput+",categorie_concernees.name|l|"+this.searchInput+"";
            }else if(this.searchInput == "") {
              this.searchCol = "";
            }
          }
        }
  
      }

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    let toogle = await this.http.get<any>(url+this.search+ this.searchCol +triage + filtre ,valuesys.httpAuthOptions()).toPromise();
    this.beneficiaireListToCheck = toogle.data.data;
   

    this.path = environment.pension_concerners;
    this.currentPage = toogle.data.current_page;
    this.firstPage =this.path+"?page=1";
    this.prevPage = this.path+"?page="+(this.currentPage-1) ;
    this.nextPage = this.path+"?page="+(this.currentPage+1);
    this.lastPage = this.path +"?page="+ toogle.data.last_page ;
    this.lastNumPage = toogle.data.last_page;
    this.total = toogle.data.total;


    console.log("*****VARIABLES End point ***"+  this.firstPage)
    

    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }
    
    this.cPage_less_1 = this.currentPage - 1 
    this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }

    
    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }


  async cocherBeneficiaire(currentBeneficiaire:any, elm) {
    console.log("etudiant", currentBeneficiaire.concernees_id);

     if(elm.checked ==true){
       this.tabBeneficiaireCheck.push(currentBeneficiaire);
     }else{
       this.tabBeneficiaireCheck = this.tabBeneficiaireCheck.filter(e => e.concernees_id !== currentBeneficiaire.concernees_id); 
     }
     console.log("liste béneficiaire cocher", this.tabBeneficiaireCheck);
   }

   async cocherToutBeneficiaire(currentBeneficiaire:any = null, elm){
    console.log(currentBeneficiaire,"xxxxxxxxxx", currentBeneficiaire.length)
      if(elm.checked ==true){
        this.isCheckedElmBeneficiaire = true;
        for (let i = 0; i < currentBeneficiaire.length; i++) {
          var a = this.tabBeneficiaireCheck.indexOf(currentBeneficiaire[i].concernees_id);
          if(a == -1 ){
            this.tabBeneficiaireCheck.push(currentBeneficiaire[i]);
          }else console.log("xxxxxxx doublant xxxxxx", currentBeneficiaire[i]) 
        }
      }else{
          this.isCheckedElmBeneficiaire = false;
          for (let i = 0; i < currentBeneficiaire.length; i++) {
            this.tabBeneficiaireCheck = this.tabBeneficiaireCheck.filter(e => e.concernees_id !== currentBeneficiaire[i].concernees_id); 
        }
      }
    console.log("liste tous les béneficiaire cocher", this.tabBeneficiaireCheck);

  }


  filtreBeneficiaire(){

    // this.submitted = true;
    // // stop here if form is invalid
    // if (this.registerForm.invalid) {
    //     return;
    // }


    //   let filtre_matricule = '';
    // if(this.f_matricule != '' && this.f_matricule != undefined){
    //   filtre_matricule = ",concernees.matricule|e|" + this.f_matricule ;
    // } 

    // let endPOintFiltre = environment.pension_concerners+"/getConcerneNoCompte?page=1&where=concernees.categorie_concerne_id|e|"+ this.categorie_concernees_id  + filtre_matricule + ",concernees.etablissement_id|e|"+this.etablissement_id;
    // this.getUrlDatatable(endPOintFiltre)
    
  }
  async getCategorieByIdEtab(id,element) {
    this.categories = [];
    element.clearModel();
    let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+id, valuesys.httpAuthOptions()).toPromise();
    if(res.code == 200) {
        this.categories = res.data;
        // if(this.categories.length == 0) element.clearModel();
    }
}

}
