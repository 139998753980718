<br><div class="repMenu">
    <div class="row  d-flex  justify-content-start">     
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/DAS']" routerLinkActive="active">Tableau de bord</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/accueil/dash']"  routerLinkActive="active">Dashboard</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourse']"  routerLinkActive="active">{{__('resume.etat_res_bourse')}}</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourseUniversite']"  routerLinkActive="active">{{__('resume.etat_res_bourse_univ')}}</a>
      </div>
  </div>
  </div> 
  <div class=" bg-white p-2  repMenu mt-2 w-100">
    <div class="export">
      <button class="px-2" (click)="exportToExcel()">
       Export excel
       <img class="mb-1" src="assets/img/excel.png" alt="">
   
   
       </button>
       <button (click)="exportPdf()" class="ml-3 px-2">
         Export Pdf
         <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
   
         </button>
     </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      
      <br>

      <div class="card">
        
        <div class="card-body" style="overflow-x: scroll">


          <div class="row footer-paginate d-flex align-items-center" >
           
            <div class="col-md-12 row d-flex align-items-center justify-content-center" >
                <div class="form-group col-md-4 ">
                  <label >Date début ordre paiement (<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateDebut"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input  type="date" class="form-control" [(ngModel)]="dateDebut"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
                <div class="form-group col-md-4">
                  <label >Date fin ordre paiement(<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateFin"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input   type="date" class="form-control" [(ngModel)]="dateFin"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
              <div>
                <button [disabled]="!dateDebut || !dateFin" style="height: 44px;" type="button" (click)="filtreByDate()" name="button" class="mt-2 btn button-control" >{{__('btn.filtre')}}</button>       

              </div>
            </div>
            

          </div>
         
          <table class="table table-hover table-striped" style="width: 100%;overflow-x: scroll;" >
            <thead>
              <tr>
                <th class="text-center">{{__('resume.t1')}} <br>
                </th>
                <th class="text-center">{{__('resume.t2')}} <br>
                </th>
                <th class="text-center">{{__('resume.t3')}} <br>
                </th>
                <th class="text-center">{{__('resume.t4')}} <br>
                </th>
                <th class="text-center">{{__('resume.t5')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t6')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t7')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t8')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t9')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t10')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t11')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t12')}}  <br>
                </th>
                <th class="text-center">{{__('resume.t13')}}  <br>
                </th>
                <th class="text-center">Action<br>
                </th>
               
              </tr>
        
            </thead>
            <tbody class="body-paginate-hr" >
               <tr *ngFor="let r of etatResumeBourseList ">
                <td class="text-center">{{ r?.name }}</td> 
                <td class="text-center" nowrap >{{ addSpace(r?.nb_boursier) }}</td>
                <td class="text-center" nowrap>{{ addSpace(r?.nb_boursier_carte) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_boursier_wallet) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_paiment) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_compte_no_credite) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.montan_paiment)}}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_retrait_epoketra_PAOMA) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.montant_retrait_epoketra_PAOMA) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_retrait_paositra_PAOMA) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.montant_retrait_paositra_PAOMA) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.nb_retrait_epoketra_GAB) }}</td>
                <td class="text-center" nowrap >{{ addSpace(r?.montant_retrait_epoketra_GAB) }}</td>
                <td class="text-center" nowrap >
                  <button  (click)=goToDetailuniv(r.etablissement_id)  class="btn" style="border: 2px solid #44a1a0;">
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </td>
              
              </tr>
            </tbody>
            <tbody *ngIf="etatResumeBourseList.length == 0">
            <tr>
                <td colspan="14" class="text-center" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
        
          </table>





















        </div>
      
      </div>
    </div>

  </div>

   

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    