import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, parcours, province } from '../../../../../model/db';
import { categorie, concerner } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-demande-enroulement',
  templateUrl: './demande-enroulement.component.html',
  styleUrls: ['./demande-enroulement.component.scss']
})
export class DemandeEnroulementComponent extends Translatable implements OnInit {

  public listDemandeEnrollementEnAttent: any = [];
  public listDemandeEnrollementEnRoler: any = [];
  etabId: any = ''
  endPOint: any = ""
  searchInput: any = "";
  searchCol: any = "";
  superAdmin: any = ""
  search: any = ""
  filtreSelect: any = 10;
  tri: any = "";
  order: any = "";
  child: any = "";
  filtreStatus: any = 0;
  statusEnroler: any = "A";


  public searchColumn: string;
  public column: string;
  public childCol: string;
  public paginateListe: any = [];
  public nextPage: any = [];
  public prevPage: any = [];
  public lastPage: any = [];
  public firstPage: any = [];
  public currentPage: any = [];
  public total: any = [];
  public path: any = [];
  public lastNumPage: any = [];
  public infoData: any = [];
  public cPage_less_2: number;
  public path_cPage_less_2: any;
  public cPage_less_1: number;
  public path_cPage_less_1: any;
  public cPage_more_1: number;
  public path_cPage_more_1: any;
  public cPage_more_2: number;
  public path_cPage_more_2: any;
  endpointWithFitre: any;
  etablissementId: number;
  submitFiltre: boolean = false;
  etablissements: etablissement[] = [];
  dateDebut: string = this.formatDateYMD(new Date());
  dateFin: string = this.formatDateYMD(new Date());
  activeEtabId: number;
  where: any = "&where=1|e|1";


  constructor(private auth: AuthServiceService,
    private toastr: ToastrService,
    private helper: HelperService,
    private formBuilder: FormBuilder,
    private http: HttpClient) {
    super()
  }

  async ngOnInit() {
    this.auth.initAutority('DEN');

    let userinfo = this.auth.account()

    this.superAdmin = userinfo.info.admin;
    this.activeEtabId = userinfo.info.etablissement_id;
    if (this.superAdmin == 0) this.etabId = userinfo.info.etablissement_id;
    this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
    this.endPOint = environment.demande_enrolement + "?page=1";
    if (this.superAdmin == 0) this.getListDemandeEnrolementEnAttent(this.endPOint, ",demande_enrolement.etablissement_id|e|" + this.etabId)
    else this.getListDemandeEnrolementEnAttent(this.endPOint, '')




  }
  filtreByEtablissement() {
    let etabselect = "";
    if (this.etabId !== '') {
      this.endpointWithFitre = environment.demande_enrolement + "?where=demande_enrolement.etablissement_id|e|" + this.etabId
      etabselect = ",demande_enrolement.etablissement_id|e|" + this.etabId
    }
    // this.endPOint = environment.demande_enrolement + "?page=1";
     this.getListDemandeEnrolementEnAttent(this.endPOint, ",demande_enrolement.etablissement_id|e|" + this.etabId)
  }


  async getSearchInput() {
    this.getListDemandeEnrolementEnAttent(this.endPOint)
  }

  async getFiltreSelect() {
    this.getListDemandeEnrolementEnAttent(this.endPOint)
  }

  async getListDemandeEnrolementEnAttent(url, filtreAdd = "", col = "", refElement?: any, child?: any, searchMulti?: any) {

    // if(refElement){
    //  this.searchColumn = refElement.value
    //  this.column = col
    //  this.childCol = child
    // }

    //     console.log("COL :" +col)
    //     console.log("Search column :" +this.searchColumn)
    //     if(col != "" || this.searchColumn ){
    //       if(this.searchColumn !="" && this.searchColumn !="undefined"){
    //         if(this.superAdmin==1)
    //         this.search = "&where="+this.column+"|l|"+this.searchColumn;
    //         else
    //         this.search = ","+this.column+"|l|"+this.searchColumn;
    //       }else if(this.searchColumn == "") {
    //         this.search = "";
    //       }
    //     }else{

    //       if(col != "" || this.searchColumn ){
    //         if(this.searchColumn !="" && this.searchColumn !="undefined"){
    //           this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
    //         }else if(this.searchColumn == "") {
    //           this.searchCol = "";
    //         }
    //       }else{
    //         if(this.searchInput !="" && this.searchInput !="undefined"){
    //           this.searchCol = "&where_or=concernees.matricule |l|"+this.searchInput+",concernees.nom|l|"+this.searchInput+",concernees.telephone|l|"+this.searchInput+",concernees.email|l|"+this.searchInput+",concernees.prenom|l|"+this.searchInput;
    //         }else if(this.searchInput == "") {
    //           this.searchCol = "";
    //         }
    //       }
    //     }
    if (refElement) {
      this.searchColumn = refElement.value
      this.column = col
      this.childCol = child
    }
    let filtreDate;
    let filtreStatut = "";
    let filtreEtab = "";
    let status;

    
      if( this.dateDebut > this.dateFin ){
        console.log(this.dateDebut)
        console.log(this.dateFin)
        this.toastr.warning(this.__('msg.dateDebut_dateFin_error'),this.__("msg.warning"));
        return;
      }else{

        console.log("status", this.statusEnroler)

        if(this.statusEnroler) {
            if (this.statusEnroler == "A") status = 0;
            if (this.statusEnroler == "R") status = 1;
            if (this.statusEnroler == "E") status = 2;
          filtreStatut = ",demande_enrolement.statut|e|"+ status;
        }
        if(this.etabId) filtreEtab = ",demande_enrolement.etablissement_id|e|"+ this.etabId; 


         filtreDate = "&date_debut="+this.dateDebut +"&date_fin="+this.dateFin;
      }
      this.search = filtreDate + this.where + filtreStatut + filtreEtab;

    console.log("COL :" + col)
    console.log("filtreadd :" + filtreAdd)
    console.log("Search column :" + this.searchColumn)
    if (col != "" || this.searchColumn) {
      if (this.searchColumn != "" && this.searchColumn != "undefined") {
        if (this.superAdmin == 1)
          this.searchCol = "," + this.column + "|l|" + this.searchColumn;
        else
          this.searchCol = "," + this.column + "|l|" + this.searchColumn;
      } else if (this.searchColumn == "") {
        this.searchCol = "";
      }
    } else {

      if (col != "" || this.searchColumn) {
        if (this.searchColumn != "" && this.searchColumn != "undefined") {
          this.searchCol = "," + this.childCol + "." + this.column + "|l|" + this.searchColumn + "";
        } else if (this.searchColumn == "") {
          this.searchCol = "";
        }
      } else {
        if (this.searchInput != "" && this.searchInput != "undefined") {
          this.searchCol = "&where_or=concernees.matricule|l|" + this.searchInput + ",concernees.nom|l|" + this.searchInput + ",concernees.telephone|l|" + this.searchInput + ",concernees.email|l|" + this.searchInput + ",concernees.prenom|l|" + this.searchInput;
        } else if (this.searchInput == "") {
          this.searchCol = "";
        }
      }
    }

    let whereEtab = "";
    if (this.superAdmin == 0) {
      whereEtab = ",demande_enrolement.etablissement_id|e|" + this.etabId;
    } else {
      whereEtab = "";
    }

    
    let filtre: any = "";
    if (this.filtreSelect != "" && this.filtreSelect != "undefined") {
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage: any = "";
    if (this.tri != "" && this.tri != "undefined") {
      triage = "&__order__=" + this.order + "," + this.tri;
    }
    // + this.search
    // let toogle = await this.http.get<any>(url + filtreStatus + filtreAdd +filtre + triage + this.search ,valuesys.httpAuthOptions()).toPromise();
    let toogle = await this.http.get<any>(url + filtreAdd +this.searchCol+ filtre + triage + this.search, valuesys.httpAuthOptions()).toPromise();

    this.listDemandeEnrollementEnAttent = toogle.data.data;
    console.log("xxxx", this.listDemandeEnrollementEnAttent)

    if (this.etabId == '') {

      this.path = environment.demande_enrolement
      this.currentPage = toogle.data.current_page;
      this.firstPage = this.path + "?page=1";
      this.prevPage = this.path + "?page=" + (this.currentPage - 1);
      this.nextPage = this.path + "?page=" + (this.currentPage + 1);
      this.lastPage = this.path + "?page=" + toogle.data.last_page;
      this.lastNumPage = toogle.data.last_page;
      this.total = toogle.data.total;


      this.cPage_less_2 = 0
      this.cPage_less_1 = 0
      if (this.currentPage > 1) {

        if (this.currentPage > 2) {
          this.cPage_less_2 = this.currentPage - 2
          this.path_cPage_less_2 = this.path + "?page=" + this.cPage_less_2;
        }

        this.cPage_less_1 = this.currentPage - 1
        this.path_cPage_less_1 = this.path + "?page=" + this.cPage_less_1;
      }


      this.cPage_more_1 = 0
      this.cPage_more_2 = 0
      if (this.currentPage < this.lastNumPage) {
        this.cPage_more_1 = this.currentPage + 1
        this.path_cPage_more_1 = this.path + "?page=" + this.cPage_more_1;

        if (this.currentPage < this.lastNumPage - 1) {
          this.cPage_more_2 = this.currentPage + 2
          this.path_cPage_more_2 = this.path + "?page=" + this.cPage_more_2;
        }

      }

      let info = this.__('datatable.info').replace('_START_', toogle.data.from)
      let info2 = info.replace('_END_', toogle.data.to)
      this.infoData = info2.replace('_TOTAL_', toogle.data.total)
    } else {

      this.path = this.endpointWithFitre + ",demande_enrolement.statut|e|" + this.filtreStatus + whereEtab;

      this.currentPage = toogle.data.current_page;
      this.firstPage = this.path + "&page=1";
      this.prevPage = this.path + "&page=" + (this.currentPage - 1);
      this.nextPage = this.path + "&page=" + (this.currentPage + 1);
      this.lastPage = this.path + "&page=" + toogle.data.last_page;
      this.lastNumPage = toogle.data.last_page;
      this.total = toogle.data.total;
      this.cPage_less_2 = 0
      this.cPage_less_1 = 0
      if (this.currentPage > 1) {

        if (this.currentPage > 2) {
          this.cPage_less_2 = this.currentPage - 2
          this.path_cPage_less_2 = this.path + "&page=" + this.cPage_less_2;
        }

        this.cPage_less_1 = this.currentPage - 1
        this.path_cPage_less_1 = this.path + "&page=" + this.cPage_less_1;
      }


      this.cPage_more_1 = 0
      this.cPage_more_2 = 0
      if (this.currentPage < this.lastNumPage) {
        this.cPage_more_1 = this.currentPage + 1
        this.path_cPage_more_1 = this.path + "&page=" + this.cPage_more_1;

        if (this.currentPage < this.lastNumPage - 1) {
          this.cPage_more_2 = this.currentPage + 2
          this.path_cPage_more_2 = this.path + "&page=" + this.cPage_more_2;
        }

      }

      let info = this.__('datatable.info').replace('_START_', toogle.data.from)
      let info2 = info.replace('_END_', toogle.data.to)
      this.infoData = info2.replace('_TOTAL_', toogle.data.total)
    }
  }


  async getFiltreStatus(status: any = '') {
    if (status == "A") this.filtreStatus = 0;
    if (status == "R") this.filtreStatus = 1;
    if (status == "E") this.filtreStatus = 2;
    console.log("xxxx", this.filtreStatus)
    let etabselect = "";
    if (this.etabId !== '') {
      console.log("TesTe");
      
      this.endpointWithFitre = environment.demande_enrolement + "?where=demande_enrolement.etablissement_id|e|" + this.etabId
      etabselect = ",demande_enrolement.etablissement_id|e|" + this.etabId
    }

    this.getListDemandeEnrolementEnAttent(environment.demande_enrolement + "?page=1", etabselect);
  }
  formatDateYMD(date: Date) {
    return date.toISOString().split('T')[0];
  }

}
