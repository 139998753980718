<br>
<div class="repMenu">
    <div class="row  d-flex  justify-content-between">     
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/GEE']" routerLinkActive="active">{{__('ordre_paiment.ordre_paiement')}}</a>
        <a [routerLink]="['/dashboard/PAI/plateforme/ordrepaiement']"  routerLinkActive="active"> {{__('ordre_paiement.title')}}</a>
      </div>
  </div>
</div> 
<div  class="bg-white px-3  repMenu mt-2 d-flex justify-content-between align-items-center">
  
  <div class="export">
    <button class="px-2" (click)="exportORtoExcel()"> {{__('ordre_paiement.export_excel')}} <img class="mb-1" src="assets/img/excel.png" alt=""> </button>
    <button (click)="exportPdfOR()" class="ml-3 px-2"> {{__('ordre_paiement.export_pdf')}}  <img   class="mb-1" src="assets/img/pdf-file.png" alt=""> </button>
  </div>
  <div class="div-add pt-2"  > 
    <button class="button-control" *ngIf="autority('ORP_2')" #addBtnUser (click)="openModal(addPaiement,null,addBtnUser)" >{{__('ordre_paiement.btn_add')}}</button>
    <button class="button-control" *ngIf="autority('ORP_2')" #addBtnUser (click)="openModal(addPaiementService,null,addBtnUser)" >{{__('ordre_paiement.btn_add_web_service')}}</button>
    <a #canvaOR [href]="sourcePath" [download]="'modele_import_ordre-paiement.xlsx'" [attr.disabled]="dwld.busy" #dwld="wmDownload" class="d-none" > {{__('tooltip.download')}} </a>
    <label class="custom-file-upload ml-4" (click)="downloadCanva(canvaOR)">
      <i class="fa fa-download"></i> {{__('ordre_paiement.download_fichier')}}
    </label>
  </div>
</div> 

<div class="row">
  <div class="col-lg-12">
    <br>
    <div class="card">
      <div class="card-body">
            <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label >{{__('carte.ministere')}} (<span class="required-red" >{{__('label.require')}}</span>)

                            </label>
                            <ng-select placeholder="{{__('carte.ministere')}}"
                                       [(ngModel)]="filtre_etablissement_id"
                                       class="form-control"
                                       [readonly]="superAdmin == 0 && activeEtabId != 1"
                            >
                                <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>


                   


                    <div class="col-lg-2 d-flex align-items-center mt-1" >
                        <button (click)="getUrlDatatable(endPOint, '','','',true)" style="height: 44px;" type="submit" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>
                      </div>
                      <div class="col-lg-2 d-flex align-items-center mt-1" >
                        <button type="button" name="cancel" style="height: 44px;" class="btn button-cancel" *ngIf="filtreBaseOP" (click)="annulerFiltreBaseOP()" >{{__('btn.cancelFiltre')}} </button>             
                      </div>
            </div>
      </div>
    </div>
  </div>
</div> 


<div class="row">
    <div class="col-lg-12">
      <br><br>
      <div class="card"> 
        <div class="card-body">
          <div class="row footer-paginate" >
            <div class="col-lg-4"> 
              <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
              <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="5000">5000</option>
                <option value="10000">10000</option>
              </select> 
              <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
            </div>
            <div class="col-lg-8 btn-paginate">
              <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
            </div>

          </div>

          <table class="table table-hover table-striped" style="width: 100%;" >
            <thead>
              <tr>
                <th>{{__('ordre_paiement.n_ordre')}} <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('ordre_paiement.numero_ordre','asc')" *ngIf="tri=='ordre_paiement.numero_ordre' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('ordre_paiement.numero_ordre','desc')"  *ngIf="tri=='ordre_paiement.numero_ordre' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('ordre_paiement.numero_ordre','asc')" *ngIf="tri!='ordre_paiement.numero_ordre'" ></i> 
                  <input type="text" #numero_ordre class="inputCol" name="numero_ordre" (keyup.enter)="getUrlDatatable(endPOint, 'ordre_paiement.numero_ordre',numero_ordre, 'ordre_paiement')" id="">
                </th>
                <th>{{__('ordre_paiement.libelle')}} <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('ordre_paiement.libelle','asc')" *ngIf="tri=='ordre_paiement.libelle' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('ordre_paiement.libelle','desc')"  *ngIf="tri=='ordre_paiement.libelle' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('ordre_paiement.libelle','asc')" *ngIf="tri!='ordre_paiement.libelle'" ></i> 
                  <input type="text" #libelle class="inputCol" name="libelle" (keyup.enter)="getUrlDatatable(endPOint, 'ordre_paiement.libelle',libelle, 'ordre_paiement')" id="">
                </th>
                <th>{{__('ordre_paiement.montant_global')}} <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('montant_global','asc')" *ngIf="tri=='montant_global' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('montant_global','desc')"  *ngIf="tri=='montant_global' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('montant_global','asc')" *ngIf="tri!='montant_global'" ></i> 
                  <input type="text" #montant_global class="inputCol" name="montant_global" (keyup.enter)="getUrlDatatable(endPOint, 'montant_global',montant_global, 'ordre_paiement')" id="">
                </th>
                <th>{{__('concerner.nb_concerne')}} <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('nombre_etudiant','asc')" *ngIf="tri=='nombre_etudiant' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('nombre_etudiant','desc')"  *ngIf="tri=='nombre_etudiant' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('nombre_etudiant','asc')" *ngIf="tri!='nombre_etudiant'" ></i> 
                  <input type="text" #nombre_etudiant class="inputCol" name="nombre_etudiant" (keyup.enter)="getUrlDatatable(endPOint, 'nombre_etudiant',nombre_etudiant, 'ordre_paiement')" id="">
                </th>
                <th>{{__('ordre_paiement.categorie')}}  <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri=='categorie_concernees.name' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('categorie_concernees.name','desc')"  *ngIf="tri=='categorie_concernees.name' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri!='categorie_concernees.name'" ></i>
                  <input type="text" #name class="inputCol" name="name" (keyup.enter)="getUrlDatatable(endPOint, 'categorie_concernees.name',name, 'ordre_paiement')" id="">
                </th>
                  <!--<th>{{__('ordre_paiement.date_debut')}} <br>
                      <i class="fa fa-caret-down icon-tri" (click)="triTable('date_debut_paiement','asc')" *ngIf="tri=='date_debut_paiement' && order=='desc'" ></i>
                      <i class="fa fa-caret-up icon-tri" (click)="triTable('date_debut_paiement','desc')"  *ngIf="tri=='date_debut_paiement' && order=='asc'"  ></i>
                      <i class="fa fa-caret-left icon-tri" (click)="triTable('date_debut_paiement','asc')" *ngIf="tri!='date_debut_paiement'" ></i>
                      <input type="date" #date_debut_paiement class="inputCol" name="date_debut_paiement" (keyup.enter)="getUrlDatatable(endPOint, 'date_debut_paiement',date_debut_paiement, 'ordre_paiement')" id="">
                  </th>-->
                  <th>{{__('ordre_paiement.etablissement')}} <br>
                    <i class="fa fa-caret-down icon-tri" (click)="triTable('etablissement.name ','asc')" *ngIf="tri=='etablissement?.name ' && order=='desc'" ></i>
                    <i class="fa fa-caret-up icon-tri" (click)="triTable('etablissement?.name ','desc')"  *ngIf="tri=='etablissement?.name ' && order=='asc'"  ></i>
                    <i class="fa fa-caret-left icon-tri" (click)="triTable('etablissement?.name ','asc')" *ngIf="tri!='etablissement?.name '" ></i>
                    <input type="text" #etablissement class="inputCol" name="etablissement" (keyup.enter)="getUrlDatatable(endPOint, 'etablissement?.name ',etablissement, 'ordre_paiement')" id="">
                </th>
                <th class="actionTab text-center">{{__('table.statut')}}</th>
                <th class="actionTab" nowrap >{{__('etudiant.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="this.ordre_paiements?.length != 0" class="body-paginate-hr" >
               <tr *ngFor="let ordre_paiement of ordre_paiements">
                    <td class="text-left">{{ ordre_paiement?.numero_ordre }}</td>
                    <td class="text-left">{{ ordre_paiement?.libelle }}</td>
                    <td class="text-right">{{ formatNumber(ordre_paiement?.montant_global, ' ') }}</td>
                    <td class="text-right">{{  formatNumber(ordre_paiement?.nombre_concernees, ' ')  }}</td>
                    <td class="text-center">{{ ordre_paiement?.categorie }}</td>
                    <!--<td class="text-center">{{ ordre_paiement?.date_debut_paiement | date : 'dd-MM-y' }}</td>
                    <td class="text-center">{{ ordre_paiement?.date_fin_paiement | date : 'dd-MM-y' }}</td>-->
                   <td class="text-center">{{ ordre_paiement?.etablissement }}</td>

                   <td class="text-center">
                       <span *ngIf="ordre_paiement.statut == -1" style="color: #858584;" > {{__('tooltip.brouillon')}}</span>
                       <span *ngIf="ordre_paiement.statut == 0" style="color: #f5c421;" > {{__('tooltip.attente')}}</span>
                       <span  *ngIf="ordre_paiement.statut == 1" style="color: #44a1a0;"  >{{__('tooltip.valide')}}</span>
                       <span  *ngIf="ordre_paiement.statut == 2" style="color: #f3a8a2;"   >{{__('tooltip.rejete')}}</span>
                       <span  *ngIf="ordre_paiement.statut == 3" style="color: blue"   >{{__('ordre_paiement.envoie')}}</span>
                    </td>
                    <td class="text-center">
                        <i class="fa fa-edit icon-table" *ngIf="autority('ORP_3') && ordre_paiement.statut != 3 && ordre_paiement.statut != 1" ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(addPaiement,ordre_paiement,openModalBtnEdit)" ></i>
                        <i class="fa fa-eye icon-table vert" *ngIf="autority('ORP_4')" ngbTooltip="{{__('tooltip.details')}}" #openModalDetail (click)="openModalDetails(showDetail,ordre_paiement,openModalDetail)" ></i>
                        <i class="fa fa-tag icon-table vert" *ngIf="autority('ORP_8')" ngbTooltip="Ajout des pièces jointes" #openUpdatePJ (click)="openModalPJ(updatePieceJoint,ordre_paiement,openUpdatePJ)" ></i>
                      </td>
              </tr>
            </tbody>
            <tbody *ngIf="ordre_paiements?.length == 0">
              <tr>
                <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
              </tr>
            </tbody> 
          </table>
          <div class="row footer-paginate" >
            <div class="col-lg-4"> 
              <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
            </div>
            <div class="col-lg-8 btn-paginate">
              <button (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
              <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
              <span *ngIf="currentPage > 3" class="three-point" > ... </span>
              <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
              <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
              <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
              <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
              <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
              <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
              <button (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
              <button (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
            </div>

          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #addPaiement style="background-color: red; width: auto;">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="row" style="padding-top: 3rem;">
      <div class="text-center col-4">
        <button type="button"[ngClass]="{'headeractive':stepperEtape1,'headernonactive':!stepperEtape1}">1</button>
        <div class="step-title steptitlepadding"> {{__('ordre_paiement.form')}} </div>            
      </div>
      <div class="text-center col-4">
        <button type="button"[ngClass]="{'headeractive':stepperEtape2,'headernonactive':!stepperEtape2}">2</button>
        <div class="step-title steptitlepadding" *ngIf="isAdd == true"  >{{__('ordre_paiment.search_user_concerne')}} </div>
        <div class="step-title steptitlepadding" *ngIf="isAdd == false"  >{{__('ordre_paiement.add_etudiant_checked')}} </div>
      </div>
      <div class="text-center col-4">
        <button type="button" [ngClass]="{'headeractive':stepperEtape3,'headernonactive':!stepperEtape3}">3</button>
        <div class="step-title steptitlepadding" *ngIf="isAdd == true" >{{__('ordre_paiement.etudiant_checked')}} </div>
        <div class="step-title steptitlepadding" *ngIf="isAdd == false" >{{__('ordre_paiement.maj_etudiant_checked')}} </div>
      </div>
    </div>
  <ng-stepper #cdkStepper [linear]="true" >
    <cdk-step  [optional]="true" [completed]="step2Active">
                <ng-template cdkStepLabel> </ng-template>

        <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addTest(clickNext)">
          
          <div class="card">
            <div class="card-body"> 

                <div class="modal-body">
                    <div class="row"> 
                        <div class="col-lg-4"> 
                          <div class="form-group">
                              <label >
                                  {{__('ordre_paiement.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                  <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback">
                                      <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                                  </span>
                              </label>
                              <ng-select 
                              aria-readonly=""
                                  formControlName="etablissement_id" 
                                  placeholder = "{{__('ordre_paiement.etablissement')}}"
                                  [(ngModel)]="ordre_paiement.etablissement_id" 
                                  class="form-control"
                                  [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                                  [readonly]="((superAdmin == 0 || isAdd == false) && activeEtabId != 1) || ordre_paiement.tresor == 1"
                                  name = "etablissement_id"
                                  (change)="getCategorieByIdEtab($event,categorieEl)"
                                  >
                                  <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                              </ng-select>
                          </div>
                      </div>

                      <div class="col-lg-4">

                        <div class="form-group">
                          <label >
                              {{__('ordre_paiement.nombre_mois')}} (<span class="required-red" >{{__('label.require')}}</span>)
                              <span *ngIf="submitted && f.nombre_mois.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.nombre_mois.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                          </label>
                          <select  [ngClass]="{ 'invalid-form-input-validate': submitted && f.nombre_mois.errors }"
                          name = "nombre_mois" formControlName="nombre_mois" (change)="changeMois()" [(ngModel)]="ordre_paiement.nombre_mois"  class="form-control" >
                            <option value="">-- Sélectionner le nombre de mois --</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select> 
                          
                        </div>
                          
                      </div>


                      <div class="col-lg-4">
                        <div class="form-group">
                            <label >Mois (<span class="required-red" >{{__('label.require')}}</span>) </label>
                            <span *ngIf="submitted && f.libelle.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.libelle.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                            <ng-select
                                    aria-readonly=""
                                    formControlName="mois"
                                    [(ngModel)]="ordre_paiement.mois"
                                    class="form-control"
                                    [multiple] = "true"
                                    maxSelectedItems = "{{ nbMois }}"
                                    name = "allMois"
                                    style="min-height: 45px; height: auto; padding: 0;"
                                    [readonly]="!ordre_paiement.nombre_mois"

                            >
                                <ng-option  *ngFor="let item of listMount"  [value]="item?.value">{{ item?.mois }}</ng-option>
                            </ng-select>
                          </div>
                      </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label >{{__('ordre_paiement.libelle')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
                                <span *ngIf="submitted && f.libelle.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.libelle.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                                <input  type="text" #element name="libelle" id="libelle" [(ngModel)]="ordre_paiement.libelle" class="form-control"  placeholder="{{__('ordre_paiement.libelle')}}" formControlName="libelle"
                                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.libelle.errors }"  >
                            </div>
                        </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                            <label >
                              {{__('ordre_paiement.date_debut_paiement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                              <span *ngIf="submitted && f.date_debut_paiement.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.date_debut_paiement.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                            </label>
                            <input 
                              formControlName="date_debut_paiement" 
                              class="form-control" 
                              type="date" 
                              name="date_debut_paiement" 
                              id="date_debut_paiement"
                              [(ngModel)]="ordre_paiement.date_debut_paiement"
                              [ngClass]="{ 'invalid-form-input-validate': submitted && f.date_debut_paiement.errors }"
                            >
                        </div>
                      </div>
        
                      <div class="col-lg-6">
                        <div class="form-group">
                            <label >
                              {{__('ordre_paiement.date_fin_paiement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                              <span *ngIf="submitted && f.date_fin_paiement.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.date_fin_paiement.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                            </label>
                            <input 
                              formControlName="date_fin_paiement" 
                              class="form-control" 
                              type="date" 
                              name="date_fin_paiement" 
                              id="date_fin_paiement" 
                              [(ngModel)]="ordre_paiement.date_fin_paiement"
                              [ngClass]="{ 'invalid-form-input-validate': submitted && f.date_fin_paiement.errors }" >
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.categorie_concernees_id.errors" class="invalid-form-feedback" >                      
                              <span *ngIf="f.categorie_concernees_id.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                          </label>
                          <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                              aria-readonly=""
                              formControlName="categorie_concernees_id" 
                              [(ngModel)]="ordre_paiement.categorie_concernees_id" 
                              class="form-control"
                              #categorieEl
                              [ngClass]="{ 'invalid-form-input-validate': submitted && f.categorie_concernees_id.errors }" >
                              <ng-option  *ngFor="let categorie of categories" [value]="categorie?.categorie_concernee_id ">{{ categorie?.name }}</ng-option>
                          </ng-select> 
                        
                        </div>
                      </div>   
                      <div class="col-lg-6">
                        <div class="form-group">
                            <label >{{__('ordre_paiement.identify_ministere')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
                            <span *ngIf="submitted && f.id_ordre_ministere.errors" class="invalid-form-feedback">
                                  <span *ngIf="f.id_ordre_ministere.errors.required"> / Ce champ est obligatoire</span>
                              </span>
                            <input  type="text" #element name="id_ordre_ministere" id="id_ordre_ministere" [readOnly]="isAdd == false" [(ngModel)]="ordre_paiement.id_ordre_ministere" class="form-control"  placeholder="{{__('ordre_paiement.identify_ministere')}}" formControlName="id_ordre_ministere" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.id_ordre_ministere.errors }"  >
                          </div>
                      </div>

                  </div>
                  <div class="row" *ngIf="show_equipement == true" > 
                                  
                    <div class="col-lg-6" >
                      <div class="form-group" >
                        <label >{{__('ordre_paiement.equipement')}} </label>
                        <span *ngIf="submitted && f.etat_equipement.errors" class="invalid-form-feedback">
                          <span *ngIf="f.etat_equipement.errors.required"> / Ce champ est obligatoire</span>
                        </span>  <br>
                        <div class="form-check form-check-inline mr-1" >
                        <input class="form-check-input" type="radio" [(ngModel)]="ordre_paiement.etat_equipement" [checked]="ordre_paiement.etat_equipement == '1'"  name="etat_equipement" id="inlineRadio1" value="1" formControlName="etat_equipement" 
                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.etat_equipement.errors }">
                        <label class="form-check-label"  >Oui</label>
                        </div>
                      
                        <div class="form-check form-check-inline mr-1" >
                          <input class="form-check-input" type="radio" [(ngModel)]="ordre_paiement.etat_equipement" [checked]="ordre_paiement.etat_equipement == '0'"  name="etat_equipement" id="inlineRadio1" value="0" formControlName="etat_equipement" 
                          [ngClass]="{ 'invalid-form-input-validate': submitted && f.etat_equipement.errors }">
                          <label class="form-check-label"  >Non</label>
                        </div>
                        
                      </div>
                      <div class="form-group" *ngIf="ordre_paiement.etat_equipement == '1'" >
                        <span *ngIf="submitted && f.equipement.errors" class="invalid-form-feedback">
                          <span *ngIf="f.equipement.errors.required"> / Ce champ est obligatoire</span>
                        </span>  <br>
                        <input  type="text" #elementPrime name="equipement" id="equipement"  [(ngModel)]="ordre_paiement.equipement" class="form-control" (focusout)="onFocusOutEventPrime($event)"   (input)="controleMontantPrime(elementPrime)" placeholder="{{__('ordre_paiement.montant_equipement')}}" formControlName="equipement" 
                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.equipement.errors }" > 
                      </div>
                      
                      </div>

                      <div class="col-lg-6"> 
                        <div class="form-group">
                            <label >
                                {{__('ordre_paiement.niveau')}} 
                                <span *ngIf="submitted && f.niveau_id.errors" class="invalid-form-feedback">
                                    <span *ngIf="f.niveau_id.errors.required"> / Ce champ est obligatoire</span>
                                </span>
                            </label>
                            <ng-select 
                                formControlName="niveau_id" 
                                [(ngModel)]="ordre_paiement.niveau_id" 
                                class="form-control"
                                [ngClass]="{ 'invalid-form-input-validate': submitted && f.niveau_id.errors }"
                                name = "niveau_id"
                                >
                                <ng-option  *ngFor="let niveau of niveaux" [value]="niveau?.id ">{{ niveau?.name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    

                  </div>
                  <div class="row">
                      <div class="col-lg-12">
                        <button type="submit" name="suivant" class="btn mb-4 button-control"  >{{__('btn.suivant')}}</button>            
                        <button type="button" name="button" #clickNext style="display: none;"  class="btn mb-4 button-control" cdkStepperNext  >{{__('btn.suivant-111')}}</button>            
                        <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
                      </div>  
                  </div>
                  
                </div>
            </div>
          </div>

        </form>
    </cdk-step>

    <cdk-step  [optional]="true" [completed]="step4Active">
                <ng-template cdkStepLabel>
                </ng-template>

                <tabset class="tabModal">

                  <tab heading="{{__('ordre_paiement.form')}}">

                    <div class="card">
                      <div class="card-body"> 
                            <div class="row footer-paginate"  >
                                <div class="col-lg-4"> 
                                  <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                                  <select name="" id="" [(ngModel)]="e_filtreSelect" (change)="e_getFiltreSelect()" class="search-input" >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                    <option value="5000">5000</option>
                                    <option value="10000">10000</option>
                                    <option value="15000">15000</option>
                                    <option value="20000">20000</option>
                                    <option value="25000">25000</option>
                                    <option value="30000">30000</option>
                                    <option value="">Tout</option>
                             
                                  </select> 
                                  <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                                </div>
                                <div class="col-lg-8 btn-paginate" >
                                  <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="e_searchInput" (keyup.enter)="e_getSearchInput()" class="search-input">
                                </div>
                    
                              </div>

                            <table class="table table-hover table-striped" style="width: 100%;" fixed-header>
                              <thead class="detail">
                                <tr fixed-header>
                                  <th>{{__('etudiant.num_compte')}} </th>                                  
                                  <th>{{__('etudiant.matricule')}} </th>                                  
                                  <th>{{__('etudiant.nom')}} </th>
                                  <th>{{__('etudiant.prenom')}} </th>
                                  <th *ngIf="type_etablissement_etab_id == 1">{{__('ordre_paiement.niveau')}} </th>
                                  <!-- <th>{{__('etudiant.email')}} </th>
                                  <th>{{__('etudiant.telephone')}} </th> 
                                  <th>{{__('etudiant.numCarte')}} </th>-->
                                  <th>{{__('ordre_paiement.type_compte')}} </th>
                                  
                  
                                  <th class="text-center" >
  
  
                                    <input  #checkAll  type="checkbox" name="" *ngIf="isAdd == false" ngbTooltip="Cocher tout / Décocher tout"  (click)="cocherToutBeneficiaire_modif(etudiants_boursier,checkAll)" >
                                    <input  #checkAll  type="checkbox" name="" *ngIf="isAdd == true"  ngbTooltip="Cocher tout / Décocher tout"  (click)="cocherToutBeneficiaire_ajout(etudiants_boursier,checkAll)" >
  
                                  </th>
                                </tr>
                              </thead>
                              <tbody *ngIf="this.etudiants_boursier?.length != 0" class="body-paginate-hr detail" >
                                <tr *ngFor="let etudiant of etudiants_boursier">
                                  <td class="text-left">{{ etudiant?.num_compte }}</td>
                                  <td class="text-left">{{ etudiant?.matricule }}</td>
                                  <td class="text-left">{{ etudiant?.nom }}</td>
                                  <td class="text-left">{{ etudiant?.prenom }}</td>
                                  <td class="text-left" *ngIf="type_etablissement_etab_id == 1">{{ etudiant?.niveau }}</td><!-- 
                                  <td class="text-left">{{ etudiant?.email }}</td>
                                   <td class="text-left">{{ etudiant?.telephone }}</td> 
                                  <td class="text-left">{{ etudiant?.carte_numero_serie }}</td> -->
                                  <td class="text-left">
                                    <span *ngIf="etudiant.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                                    <span *ngIf="etudiant.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                                </td>
                          
                                  <td class="text-center" >
                                    <input #checkboxesEtudiants type="checkbox" [checked]="isCheckedElmEtudiant"  name="" [ngModel]="" *ngIf="isAdd == false" (click)="cocherBeneficiaire_modif(etudiant,checkboxesEtudiants)" >
                                    <input #checkboxesEtudiants type="checkbox" [checked]="isCheckedElmEtudiant"  name="" [ngModel]="" *ngIf="isAdd == true" (click)="cocherBeneficiaire_ajout(etudiant,checkboxesEtudiants)" >
                              
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="etudiants_boursier?.length == 0">
                              <tr>
                                  <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
                              </tr>
                            </tbody> 
                          
                            </table>
  
  
                            <div class="row footer-paginate" >
                              <div class="col-lg-4"> 
                                <span class="three-point" style="padding-left: 0px;" >{{e_infoData}}</span> 
                              </div>
                              <div class="col-lg-8 btn-paginate">
                                <button (click)="getListDatatableBeneficiary(e_firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                                <button (click)="getListDatatableBeneficiary(e_prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                                <button *ngIf="e_currentPage > 3" class="btn-pagination-hr"  (click)="getListDatatableBeneficiary(e_firstPage)" > 1 </button>
                                <span *ngIf="e_currentPage > 3" class="three-point" > ... </span>
                                <button *ngIf="e_cPage_less_2 > 0 && e_currentPage < 4" class="btn-pagination-hr"  (click)="getListDatatableBeneficiary(e_path_cPage_less_2)" > {{e_cPage_less_2}} </button>
                                <button *ngIf="e_cPage_less_1 > 0" class="btn-pagination-hr" (click)="getListDatatableBeneficiary(e_path_cPage_less_1)" > {{e_cPage_less_1}} </button>
                                <button (click)="getListDatatableBeneficiary(e_prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{e_currentPage}} </button>
                                <button *ngIf="e_cPage_more_1 > 0" class="btn-pagination-hr" (click)="getListDatatableBeneficiary(e_path_cPage_more_1)" > {{e_cPage_more_1}} </button>
                                <button *ngIf="(e_cPage_more_2 > 0 && e_cPage_more_2 > e_lastNumPage && e_cPage_more_1 > e_lastNumPage && e_currentPage > e_lastNumPage) || e_cPage_more_2 == e_lastNumPage" class="btn-pagination-hr"  (click)="getListDatatableBeneficiary(e_path_cPage_more_2)" > {{e_cPage_more_2}} </button>
                                <span *ngIf="e_cPage_more_2 < e_lastNumPage && e_cPage_more_1 < e_lastNumPage && e_currentPage < e_lastNumPage" class="three-point" > ... </span>
                                <button *ngIf="e_cPage_more_2 < e_lastNumPage && e_cPage_more_1 < e_lastNumPage && e_currentPage < e_lastNumPage" class="btn-pagination-hr"  (click)="getListDatatableBeneficiary(e_lastPage)" > {{e_lastNumPage}} </button>
                                <button (click)="getListDatatableBeneficiary(e_nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                                <button (click)="getListDatatableBeneficiary(e_lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                              </div>
                  
                            </div>
  
                      </div>
                    </div>

                  <button type="button" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true && tabEtudiantCheck.length != 0" (click) = "listeEtudiantSelectionner_ajout()"  cdkStepperNext >{{__('btn.suivant')}}</button>            
                  <button type="button" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false" (click) = "listeEtudiantSelectionner_modif()"  cdkStepperNext >{{__('btn.suivant')}}</button>            
                  <button type="button" name="button" class="btn mb-4 button-cancel" (click)="previousToStep1()" cdkStepperPrevious  >{{__('tooltip.precedent')}}</button>   

                    <br>
                  </tab>
    
                  <tab heading="{{__('ordre_paiement.import')}}" *ngIf="autority('ORP_7')" >
                    
                    <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="AddImportExcel()">
                    
                          <div class="row"> 
                            <div class="col-lg-6">
                              <br>
                              <input
                              (change)="handleInputChange($event)" 
                              #documentFile 
                              accept="text/xlsx*"
                              id="documentFileImport" 
                              name="document" 
                              type="file">
                          </div>
                          </div>
                    
                        

                        <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true " cdkStepperNext >{{__('btn.suivant')}}</button>            
                        <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  cdkStepperNext >{{__('btn.suivant')}}</button>            
                        <button type="button" name="button" class="btn mb-4 button-cancel" (click)="previousToStep1()" cdkStepperPrevious  >{{__('tooltip.precedent')}}</button>   

                    </form>
                      
                    <br>
                  </tab>
                
                </tabset>



                        

    </cdk-step>

    <cdk-step  [optional]="true" [completed]="step3Active" >
                <ng-template cdkStepLabel>
                </ng-template>
                    <form action="" (ngSubmit)="addPaiementForm()" ngNativeValidate>

                      <div class="card" *ngIf="isAdd == false">
                        <div class="card-body"> 
                          
                          <div style="text-align: center; font-weight: bold; margin: 0 30px; ">Liste des bénéficiaires actuel</div> <br>

                        
                    
                              <div class="row footer-paginate"  >
                                <div class="col-lg-4"> 
                                  <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                                  <select name="" id="" [(ngModel)]="detail_e_filtreSelect" (change)="e_detail_e_getFiltreSelect()" class="search-input" >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="2000">2000</option>
                                    <option value="5000">5000</option>
                                    <option value="10000">10000</option>
                                  </select> 
                                  <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                                </div>
                                <div class="col-lg-8 btn-paginate">
                                  <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="e_detail_searchInput" (keyup.enter)="e_getDetailSearchInput()" class="search-input">
                                </div>

                              </div>

                              <table class="table table-hover table-striped" style="width: 100%;" fixed-header >
                                <thead class="detail">
                                  <tr fixed-header>
                                    <th class="text-center" >{{__('etudiant.num_compte')}} </th>
                                    <th class="text-center" >{{__('etudiant.matricule')}} </th>
                                    <th class="text-center" >{{__('etudiant.nom')}} </th>
                                    <th class="text-center" >{{__('etudiant.prenom')}} </th><!-- 
                                    <th>{{__('etudiant.telephone')}} </th>
                                    <th>{{__('etudiant.email')}} </th>
                                    <th>{{__('etudiant.numCarte')}}</th> -->
                                    <th class="text-center" >{{__('ordre_paiement.type_compte')}} </th>
                                    <th class="text-center">{{__('ordre_paiement.montant_global')}}</th>


                                    <th  class="text-center" nowrap  *ngIf="isAdd == false && ordre_paiement.tresor == 0" >A supprimer</th>

                                  </tr>
                                </thead>
                                <tbody *ngIf="this.etudiants_boursier_in_data?.length != 0" class="body-paginate-hr detail" >
                                  <tr *ngFor="let etudiant of etudiants_boursier_in_data">
                                    <td class="text-left">{{ etudiant?.num_compte }}</td>
                                    <td class="text-center">{{ etudiant.matricule }}</td>                           
                                    <td class="text-left">{{ etudiant?.nom }}</td>
                                    <td class="text-left">{{ etudiant?.prenom }}</td><!-- 
                                    <td class="text-left">{{ etudiant.telephone }}</td>                           
                                    <td class="text-left">{{ etudiant?.email}}</td>     
                                    <td class="text-left">{{ etudiant?.numero_serie }}</td> -->
                                    <td class="text-center">
                                        <span *ngIf="etudiant.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                                        <span *ngIf="etudiant.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                                    </td>
                                    <td class="text-right">{{ formatNumber(etudiant?.montant, ' ')}}</td>

                              
                                    <td class="text-center" *ngIf="isAdd == false && ordre_paiement.tresor == 0" >
                                      <input #check type="checkbox" name="" (click)="cocherBeneficiaire_suppr(etudiant, check)" >
                                    </td>
                                  
                                  </tr>
                                </tbody>
                                <tbody *ngIf="etudiants_boursier_in_data?.length == 0">
                                <tr>
                                    <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
                                </tr>
                                <tbody> 
                            
                              </table>


                              <div class="row footer-paginate" >
                                <div class="col-lg-4"> 
                                  <span class="three-point" style="padding-left: 0px;" >{{paginateChecked.e_infoData}}</span> 
                                </div>
                                <div class="col-lg-8 btn-paginate">
                                  <button (click)="getListBeneficiaryinData(paginateChecked.e_firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                                  <button (click)="getListBeneficiaryinData(paginateChecked.e_prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                                  <button *ngIf="paginateChecked.e_currentPage > 3" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_firstPage)" > 1 </button>
                                  <span *ngIf="paginateChecked.e_currentPage > 3" class="three-point" > ... </span>
                                  <button *ngIf="paginateChecked.e_cPage_less_2 > 0 && paginateChecked.e_currentPage < 4" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_less_2)" > {{paginateChecked.e_cPage_less_2}} </button>
                                  <button *ngIf="paginateChecked.e_cPage_less_1 > 0" class="btn-pagination-hr" (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_less_1)" > {{paginateChecked.e_cPage_less_1}} </button>
                                  <button (click)="getListBeneficiaryinData(paginateChecked.e_prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{paginateChecked.e_currentPage}} </button>
                                  <button *ngIf="paginateChecked.e_cPage_more_1 > 0" class="btn-pagination-hr" (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_more_1)" > {{paginateChecked.e_cPage_more_1}} </button>
                                  <button *ngIf="(paginateChecked.e_cPage_more_2 > 0 && paginateChecked.e_cPage_more_2 > paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 > paginateChecked.e_lastNumPage && paginateChecked.e_currentPage > paginateChecked.e_lastNumPage) || paginateChecked.e_cPage_more_2 == paginateChecked.e_lastNumPage" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_more_2)" > {{paginateChecked.e_cPage_more_2}} </button>
                                  <span *ngIf="paginateChecked.e_cPage_more_2 < paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 < paginateChecked.e_lastNumPage && paginateChecked.e_currentPage < paginateChecked.e_lastNumPage" class="three-point" > ... </span>
                                  <button *ngIf="paginateChecked.e_cPage_more_2 < paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 < paginateChecked.e_lastNumPage && paginateChecked.e_currentPage < paginateChecked.e_lastNumPage" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_lastPage)" > {{paginateChecked.e_lastNumPage}} </button>
                                  <button (click)="getListBeneficiaryinData(paginateChecked.e_nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                                  <button (click)="getListBeneficiaryinData(paginateChecked.e_lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                                </div>

                              </div>





                        </div>
                      </div>



                      <div class="card">
                        <div class="card-body"> 
                          
                          <div style="text-align: center; font-weight: bold; margin: 0 30px; ">{{__('ordre_paiement.liste_beneficiaire_concerne')}} </div> <br>
                          <div class="text-center">
                                <span style="padding-right:50px" >{{__('ordre_paiement.nb_beneficiaire_concerne')}} :<b >{{ formatNumber(etudiants_checked_boursier?.length, ' ') }}</b >  </span>
                          </div>
                          <br>
                          <table class="table table-hover table-striped" style="width: 100%; " fixed-header >
                                <thead class="detail">
                                  <tr fixed-header>
                                    
                                    <th nowrap>{{__('etudiant.num_compte')}} </th>
                                    <th class="text-center">{{__('etudiant.matricule')}} </th>
                                    <th class="text-left">{{__('etudiant.nom')}} </th>
                                    <th class="text-left">{{__('etudiant.prenom')}} </th>
                                    <th class="text-center" [ngStyle]="{'display': (type_etablissement_etab_id == 0 || importListOP == true) ? 'none' : 'revert'}">{{__('ordre_paiement.niveau')}} </th><!-- 
                                    <th>{{__('bachelier.telephone')}} </th>
                                    <th>{{__('etudiant.email')}} </th>
                                    <th>{{__('etudiant.numCarte')}} </th> -->
                                    <th class="text-center">{{__('ordre_paiement.type_compte')}} </th>
                                    <th class="text-center" [ngStyle]="{'display': (type_etablissement_etab_id == 0 || importListOP == true) ? 'none' : 'revert'}" >{{__('ordre_paiement.montant_bourse')}}</th>
                                    <th class="text-center">{{__('ordre_paiement.montant_global')}}</th>

                                  </tr>
                                </thead>
                                <tbody *ngIf="this.etudiants_checked_boursier?.length != 0" class="body-paginate-hr detail" >
                              
                                  <tr *ngFor="let etudiant of etudiants_checked_boursier" class="test">
                                    <td class="text-left" nowrap >{{ etudiant?.num_compte }}</td>
                                    <td class="text-center">{{ etudiant?.matricule}}</td>                                
                                    <td class="text-left">{{ etudiant?.nom }}</td>
                                    <td class="text-left">{{ etudiant?.prenom }}</td>
                                    <td class="text-center" [ngStyle]="{'display': (type_etablissement_etab_id == 0 || importListOP == true) ? 'none' : 'revert'}">{{ etudiant?.niveau }}</td><!-- 
                                    <td class="text-left">{{ etudiant?.telephone }}</td>
                                    <td class="text-left">{{ etudiant?.email}}</td>                                
                                    <td class="text-left">{{ etudiant?.carte_numero_serie }}</td> -->
                                    <td class="text-center">
                                        <span *ngIf="etudiant.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                                        <span *ngIf="etudiant.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                                    </td>
                                    <td [ngStyle]="{'display': (type_etablissement_etab_id == 0 || importListOP == true) ? 'none' : 'revert'}" class="text-right">{{etudiant?.montant_bourse}}</td>
                                    <td>
                                      <input type="number" required #input_Montant (input)="montantInput(input_Montant)" [readonly]="isListTrue == true || type_etablissement_etab_id == 1"  min="0" [value]=" importListOP == false ? (etudiant?.montant_bourse * ordre_paiement.nombre_mois) + + ordre_paiement.equipement : etudiant?.montant "  placeholder="{{__('categorie_concerner.montant')}}" >
                                    </td>
                                    

                                    <td style="display: none;" >
                                      <input type="text" [value]="etudiant?.concernees_id" >
                                    </td>
                                    <td style="display: none;" >
                                      <input type="text" [value]="etudiant?.carte_numero_serie" >
                                    </td>
                          
                                  </tr>
                                
                                </tbody>
                                <tbody *ngIf="etudiants_checked_boursier?.length == 0">
                                <tr>
                                    <td colspan="7" class="text-center" >{{__('label_no_data')}}</td>
                                </tr>
                                <tbody> 
                            
                              </table>

                        </div>
                      </div>


                      <button type="submit" name="button" class="btn mb-4 button-control"  *ngIf="isAdd == true"   >{{__('tooltip.enreg')}}</button>            
                      <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>            
                      <button type="button" name="button" class="btn mb-4 button-cancel" #prec (click)="previousToStep2(prec)" cdkStepperPrevious  >{{__('tooltip.precedent')}}</button>            

                    </form>
    </cdk-step>

  </ng-stepper>

</ng-template>









<ng-template #addPaiementService style="background-color: red; width: auto;">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="row" style="padding-top: 3rem;">
    <div class="text-center col-4">
      <button type="button"[ngClass]="{'headeractive':stepperEtape1,'headernonactive':!stepperEtape1}">1</button>
      <div class="step-title steptitlepadding"> {{__('ordre_paiement.form')}} </div>            
    </div>
    <div class="text-center col-4">
      <button type="button"[ngClass]="{'headeractive':stepperEtape2,'headernonactive':!stepperEtape2}">2</button>
      <div class="step-title steptitlepadding" *ngIf="isAdd == true"  >{{__('ordre_paiement.view_form')}} </div>
      <div class="step-title steptitlepadding" *ngIf="isAdd == false"  >{{__('ordre_paiement.add_etudiant_checked')}} </div>
    </div>
    <div class="text-center col-4">
      <button type="button" [ngClass]="{'headeractive':stepperEtape3,'headernonactive':!stepperEtape3}">3</button>
      <div class="step-title steptitlepadding" *ngIf="isAdd == true" >{{__('ordre_paiement.etudiant')}} </div>
      <div class="step-title steptitlepadding" *ngIf="isAdd == false" >{{__('ordre_paiement.maj_etudiant_checked')}} </div>
    </div>
  </div>
<ng-stepper #cdkStepper [linear]="true" >
  <cdk-step  [optional]="true" [completed]="step2Active">
              <ng-template cdkStepLabel> </ng-template>

      <form ngNativeValidate  (ngSubmit)="nextStep(clickNextService)">
        
        <div class="card">
          <div class="card-body"> 

              <div class="modal-body">
                  <div class="row"> 
                      <div class="col-lg-4"> 
                        <div class="form-group">
                            <label >
                                {{__('ordre_paiement.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            
                            </label>
                            <ng-select 
                            aria-readonly=""
                                [(ngModel)]="ordre_paiement.etablissement_id" 
                                class="form-control"
                                placeholder = "{{__('ordre_paiement.etablissement')}}"
                                [readonly]="(superAdmin == 0 || isAdd == false) && activeEtabId != 1"
                                name = "etablissement_id"
                                (change)="getCategorieByIdEtab($event,categorieEl)"
                                >
                                <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group">
                        <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                     
                        </label>
                        <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                            aria-readonly=""
                            [(ngModel)]="ordre_paiement.categorie_concernees_id" 
                            class="form-control"
                            #categorieEl
                           >
                            <ng-option  *ngFor="let categorie of categories" [value]="categorie?.categorie_concernee_id ">{{ categorie?.name }}</ng-option>
                        </ng-select> 
                      
                      </div>
                    </div>   
                    <div class="col-lg-4">
                      <div class="form-group">
                          <label >{{__('ordre_paiement.num_ov_tresor')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
                      
                          <input  type="text" #element name="id_ordre_ministere" id="id_ordre_ministere" [(ngModel)]="ordre_paiement.id_ordre_ministere" class="form-control"  placeholder="{{__('ordre_paiement.num_ov_tresor')}}"  
                           >
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-12">
                      <button type="submit" name="suivant" class="btn mb-4 button-control"  [disabled]="!ordre_paiement.id_ordre_ministere || !ordre_paiement.categorie_concernees_id || !ordre_paiement.etablissement_id || showbtn == false" >{{__('btn.suivant')}}</button>            
                      <button type="button" name="button" #clickNextService style="display: none;"  class="btn mb-4 button-control" cdkStepperNext  >{{__('btn.suivant-111')}}</button>            
                      <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
                    </div>  
                </div>


                <div class="progress-outer" *ngIf="showProgress == true">
                  <div class="progress-inner" [style.width]="progressBar + '%'" style="background-color: #44a1a0;">
                    <ng-container> 
                      {{progressBar}}%
                    </ng-container>
                   
                  </div>
                </div>

                
              </div>
          </div>
        </div>

      </form>
  </cdk-step>

  <cdk-step  [optional]="true" [completed]="step4Active">
              <ng-template cdkStepLabel>
              </ng-template>


              <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addTestService(clickNextServ)">
        
                <div class="card">
                  <div class="card-body"> 
        
                      <div class="modal-body">
                          <div class="row"> 
                              <div class="col-lg-4"> 
                                <div class="form-group">
                                    <label >
                                        {{__('ordre_paiement.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                        <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback">
                                            <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                                        </span>
                                    </label>
                                    <ng-select 
                                    aria-readonly=""
                                        formControlName="etablissement_id" 
                                        [(ngModel)]="ordre_paiement.etablissement_id" 
                                        class="form-control"
                                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                                        readonly="true"
                                        name = "etablissement_id"
                                        
                                        (change)="getCategorieByIdEtab($event,categorieEl)"
                                        >
                                        <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
        
                            <div class="col-lg-4">
        
                              <div class="form-group">
                                <label >
                                    {{__('ordre_paiement.nombre_mois')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                    <span *ngIf="submitted && f.nombre_mois.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.nombre_mois.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                </label>
                                <select  [ngClass]="{ 'invalid-form-input-validate': submitted && f.nombre_mois.errors }"
                                name = "nombre_mois" formControlName="nombre_mois" (change)="changeMois()" [(ngModel)]="ordre_paiement.nombre_mois"  class="form-control" >
                                  <option value="">-- Sélectionner le nombre de mois --</option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select> 
                                
                              </div>
                                
                            </div>
        
        
                            <div class="col-lg-4">
                              <div class="form-group">
                                  <label >Mois (<span class="required-red" >{{__('label.require')}}</span>) </label>
                                  <span *ngIf="submitted && f.libelle.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.libelle.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                  <ng-select
                                          aria-readonly=""
                                          formControlName="mois"
                                          [(ngModel)]="ordre_paiement.mois"
                                          class="form-control"
                                          [multiple] = "true"
                                          maxSelectedItems = "{{ nbMois }}"
                                          name = "allMois"
                                          style="min-height: 45px; height: auto; padding: 0;"
                                          [readonly]="!ordre_paiement.nombre_mois"
        
                                  >
                                      <ng-option  *ngFor="let item of listMount"  [value]="item?.value">{{ item?.mois }}</ng-option>
                                  </ng-select>
                                </div>
                            </div>
        
                              <div class="col-lg-12">
                                  <div class="form-group">
                                      <label >{{__('ordre_paiement.libelle')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
                                      <span *ngIf="submitted && f.libelle.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.libelle.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                      <input  type="text" #element name="libelle" id="libelle" [(ngModel)]="ordre_paiement.libelle" class="form-control"  placeholder="{{__('ordre_paiement.libelle')}}" formControlName="libelle"
                                              [ngClass]="{ 'invalid-form-input-validate': submitted && f.libelle.errors }"  >
                                  </div>
                              </div>
        
                            <div class="col-lg-6">
                              <div class="form-group">
                                  <label >
                                    {{__('ordre_paiement.date_debut_paiement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                    <span *ngIf="submitted && f.date_debut_paiement.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.date_debut_paiement.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                  </label>
                                  <input 
                                    formControlName="date_debut_paiement" 
                                    class="form-control" 
                                    type="date" 
                                    name="date_debut_paiement" 
                                    id="date_debut_paiement"
                                    [(ngModel)]="ordre_paiement.date_debut_paiement"
                                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.date_debut_paiement.errors }"
                                  >
                              </div>
                            </div>
              
                            <div class="col-lg-6">
                              <div class="form-group">
                                  <label >
                                    {{__('ordre_paiement.date_fin_paiement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                    <span *ngIf="submitted && f.date_fin_paiement.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.date_fin_paiement.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                  </label>
                                  <input 
                                    formControlName="date_fin_paiement" 
                                    class="form-control" 
                                    type="date" 
                                    name="date_fin_paiement" 
                                    id="date_fin_paiement" 
                                    [(ngModel)]="ordre_paiement.date_fin_paiement"
                                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.date_fin_paiement.errors }" >
                              </div>
                            </div>
        
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                  <span *ngIf="submitted && f.categorie_concernees_id.errors" class="invalid-form-feedback" >                      
                                    <span *ngIf="f.categorie_concernees_id.errors.required"> / Ce champ est obligatoire</span>
                                  </span>
                                </label>
                                <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                                    aria-readonly=""
                                    formControlName="categorie_concernees_id" 
                                    [(ngModel)]="ordre_paiement.categorie_concernees_id" 
                                    class="form-control"
                                    #categorieEl
                                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.categorie_concernees_id.errors }" >
                                    <ng-option  *ngFor="let categorie of categories" [value]="categorie?.categorie_concernee_id ">{{ categorie?.name }}</ng-option>
                                </ng-select> 
                              
                              </div>
                            </div>   
                            <div class="col-lg-6">
                              <div class="form-group">
                                  <label >{{__('ordre_paiement.num_ov_tresor')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
                                  <span *ngIf="submitted && f.id_ordre_ministere.errors" class="invalid-form-feedback">
                                        <span *ngIf="f.id_ordre_ministere.errors.required"> / Ce champ est obligatoire</span>
                                    </span>
                                  <input  type="text" #element readonly name="id_ordre_ministere" id="id_ordre_ministere" [(ngModel)]="ordre_paiement.id_ordre_ministere" class="form-control"  placeholder="{{__('ordre_paiement.num_ov_tresor')}}" formControlName="id_ordre_ministere" 
                                  [ngClass]="{ 'invalid-form-input-validate': submitted && f.id_ordre_ministere.errors }"  >
                                </div>
                            </div>
        
                        </div>
                        <div class="row" > 
                                        
                          <div class="col-lg-6" >
                            
                            <div class="form-group" >
                              <label >{{__('ordre_paiement.montant_global')}} (<span class="required-red" >{{__('label.require')}}</span>)</label>

                              <input  type="text" #elementPrime name="equipement" readonly id="equipement"  [(ngModel)]="ordre_paiement.equipement" class="form-control" placeholder="{{__('ordre_paiement.montant_global')}}" formControlName="equipement" 
                              [ngClass]="{ 'invalid-form-input-validate': submitted && f.equipement.errors }" > 
                            </div>
                            
                            </div>
        
                            <div class="col-lg-6"> 
                              <div class="form-group">
                                  <label >
                                      {{__('ordre_paiement.niveau')}} 
                                      <span *ngIf="submitted && f.niveau_id.errors" class="invalid-form-feedback">
                                          <span *ngIf="f.niveau_id.errors.required"> / Ce champ est obligatoire</span>
                                      </span>
                                  </label>
                                  <ng-select 
                                      formControlName="niveau_id" 
                                      [(ngModel)]="ordre_paiement.niveau_id" 
                                      class="form-control"
                                      [ngClass]="{ 'invalid-form-input-validate': submitted && f.niveau_id.errors }"
                                      name = "niveau_id"
                                      >
                                      <ng-option  *ngFor="let niveau of niveaux" [value]="niveau?.id ">{{ niveau?.name }}</ng-option>
                                  </ng-select>
                              </div>
                          </div>
        
                          
        
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                              <button type="submit" name="suivant" class="btn mb-4 button-control"  >{{__('btn.suivant')}}</button>            
                              <button type="button" name="button" #clickNextServ style="display: none;"  class="btn mb-4 button-control" cdkStepperNext  >{{__('btn.suivant-111')}}</button>            
                              <button type="button" name="button" class="btn mb-4 button-cancel" (click)="previousServiceToStep1()" cdkStepperPrevious  >{{__('tooltip.precedent')}}</button>            
                            </div>  
                        </div>
                        
                      </div>
                  </div>
                </div>
        
              </form>



              



                      

  </cdk-step>

  <cdk-step  [optional]="true" [completed]="step3Active" >
              <ng-template cdkStepLabel>
              </ng-template>
                  <form action="" (ngSubmit)="addPaiementServiceForm()" ngNativeValidate>

                    <div class="card">
                      <div class="card-body"> 
                        
                        <div style="text-align: center; font-weight: bold; margin: 0 30px; ">Liste des bénéficiaires non trouvé</div> <br>

                      
                  
                            <!-- <div class="row footer-paginate"  >
                              <div class="col-lg-4"> 
                                <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                                <select name="" id="" [(ngModel)]="detail_e_filtreSelect" (change)="e_detail_e_getFiltreSelect()" class="search-input" >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                </select> 
                                <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                              </div>
                              <div class="col-lg-8 btn-paginate">
                                <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="e_detail_searchInput" (keyup.enter)="e_getDetailSearchInput()" class="search-input">
                              </div>

                            </div> -->

                            <table class="table table-hover table-striped" style="width: 100%;" fixed-header >
                              <thead class="detail">
                                <tr fixed-header>
                                  <th class="text-center" >{{__('etudiant.num_compte')}} </th>
                                  <th class="text-center" >{{__('etudiant.nom')}} {{__('etudiant.prenom')}} </th>
                                  <th class="text-center" >{{__('ordre_paiement.code_tiers')}} </th>
                                  <th class="text-center" >{{__('ordre_paiement.titre')}} </th>
                                  <th class="text-center" >{{__('ordre_paiement.montant_global')}} </th>

                                </tr>
                              </thead>
                              <tbody *ngIf="this.etudiants_boursier_in_data?.length != 0" class="body-paginate-hr detail" >
                                <tr *ngFor="let etudiant of etudiants_boursier_in_data">
                                  <td class="text-center">{{ etudiant.compte }}</td>                           
                                    <td class="text-center">{{ etudiant?.nomPrenoms }}</td>
                                    <td class="text-center">{{ etudiant?.codeTiers }}</td>
                                  <td class="text-center">{{ etudiant?.titre }}</td>
                                  <td class="text-right">{{ etudiant.montant }}</td>  
                                  
                                </tr>
                              </tbody>
                              <tbody *ngIf="etudiants_boursier_in_data?.length == 0">
                              <tr>
                                  <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
                              </tr>
                              <tbody> 
                          
                            </table>


                            <!-- <div class="row footer-paginate" >
                              <div class="col-lg-4"> 
                                <span class="three-point" style="padding-left: 0px;" >{{paginateChecked.e_infoData}}</span> 
                              </div>
                              <div class="col-lg-8 btn-paginate">
                                <button (click)="getListBeneficiaryinData(paginateChecked.e_firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                                <button (click)="getListBeneficiaryinData(paginateChecked.e_prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                                <button *ngIf="paginateChecked.e_currentPage > 3" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_firstPage)" > 1 </button>
                                <span *ngIf="paginateChecked.e_currentPage > 3" class="three-point" > ... </span>
                                <button *ngIf="paginateChecked.e_cPage_less_2 > 0 && paginateChecked.e_currentPage < 4" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_less_2)" > {{paginateChecked.e_cPage_less_2}} </button>
                                <button *ngIf="paginateChecked.e_cPage_less_1 > 0" class="btn-pagination-hr" (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_less_1)" > {{paginateChecked.e_cPage_less_1}} </button>
                                <button (click)="getListBeneficiaryinData(paginateChecked.e_prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{paginateChecked.e_currentPage}} </button>
                                <button *ngIf="paginateChecked.e_cPage_more_1 > 0" class="btn-pagination-hr" (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_more_1)" > {{paginateChecked.e_cPage_more_1}} </button>
                                <button *ngIf="(paginateChecked.e_cPage_more_2 > 0 && paginateChecked.e_cPage_more_2 > paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 > paginateChecked.e_lastNumPage && paginateChecked.e_currentPage > paginateChecked.e_lastNumPage) || paginateChecked.e_cPage_more_2 == paginateChecked.e_lastNumPage" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_path_cPage_more_2)" > {{paginateChecked.e_cPage_more_2}} </button>
                                <span *ngIf="paginateChecked.e_cPage_more_2 < paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 < paginateChecked.e_lastNumPage && paginateChecked.e_currentPage < paginateChecked.e_lastNumPage" class="three-point" > ... </span>
                                <button *ngIf="paginateChecked.e_cPage_more_2 < paginateChecked.e_lastNumPage && paginateChecked.e_cPage_more_1 < paginateChecked.e_lastNumPage && paginateChecked.e_currentPage < paginateChecked.e_lastNumPage" class="btn-pagination-hr"  (click)="getListBeneficiaryinData(paginateChecked.e_lastPage)" > {{paginateChecked.e_lastNumPage}} </button>
                                <button (click)="getListBeneficiaryinData(paginateChecked.e_nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                                <button (click)="getListBeneficiaryinData(paginateChecked.e_lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                              </div>

                            </div> -->





                      </div>
                    </div>



                    <div class="card">
                      <div class="card-body"> 
                        
                        <div style="text-align: center; font-weight: bold; margin: 0 30px; ">{{__('ordre_paiement.liste_beneficiaire_concerne')}} </div> <br>
                        <div class="text-center">
                              <span style="padding-right:50px" >{{__('ordre_paiement.nb_beneficiaire_concerne')}} :<b >{{ formatNumber(etudiants_checked_boursier?.length, ' ') }}</b >  </span>
                        </div>
                        <br>
                        <table class="table table-hover table-striped" style="width: 100%; " fixed-header >
                              <thead class="detail">
                                <tr fixed-header>
                                  
                                  <th class="text-center">{{__('etudiant.matricule')}} </th>
                                  <th class="text-left">{{__('etudiant.nom')}} </th>
                                  <th class="text-left">{{__('etudiant.prenom')}} </th>
                                  <th  >{{__('etudiant.num_compte')}} </th>
                                  <th>{{__('bachelier.telephone')}} </th>
                                  <th>{{__('etudiant.email')}} </th>
                                  <th class="text-center">{{__('ordre_paiement.type_compte')}} </th>
                                  <th class="text-center">{{__('ordre_paiement.montant_global')}}</th>
                                  <th class="text-center">{{__('ordre_paiement.msg')}}</th>

                                </tr>
                              </thead>
                              <tbody *ngIf="this.etudiants_checked_boursier?.length != 0" class="body-paginate-hr detail" >
                            
                                <tr *ngFor="let etudiant of etudiants_checked_boursier" class="test">
                                  <td class="text-center">{{ etudiant?.matricule}}</td>                                
                                  <td class="text-left">{{ etudiant?.nom }}</td>
                                  <td class="text-left">{{ etudiant?.prenom }}</td>
                                  <td class="text-left"   >{{ etudiant?.num_compte }}</td>
                                  <td class="text-left">{{ etudiant?.telephone }}</td>
                                  <td class="text-left">{{ etudiant?.email}}</td>                                
                                  <td class="text-center">
                                      <span *ngIf="etudiant.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                                      <span *ngIf="etudiant.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                                  </td>
                                  <td class="text-right">
                                    {{ etudiant?.montant }}
                                    <!-- <input type="number" required #input_Montant  readonly="true"  min="0" [value]="etudiant?.montant "  placeholder="{{__('categorie_concerner.montant')}}" > -->
                                  </td>
                                  <td class="text-center">
                                    <span *ngIf="etudiant.check == 0"  style="color: red;" > {{ etudiant?.check_message}} </span>
                                    <span *ngIf="etudiant.check == 1" style="color: green;"> {{ etudiant?.check_message}} </span>
                                </td>
                                  

                        
                                </tr>
                              
                              </tbody>
                              <tbody *ngIf="etudiants_checked_boursier?.length == 0">
                              <tr>
                                  <td colspan="7" class="text-center" >{{__('label_no_data')}}</td>
                              </tr>
                              <tbody> 
                          
                            </table>

                      </div>
                    </div>


                    <button type="submit" name="button" class="btn mb-4 button-control"  *ngIf="isAdd == true" [disabled] = "etudiants_boursier_in_data?.length != 0"   >{{__('tooltip.enreg')}}</button>            
                    <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>            
                    <button type="button" name="button" class="btn mb-4 button-cancel" (click)="previousToStep2()" cdkStepperPrevious  >{{__('tooltip.precedent')}}</button>            

                  </form>
  </cdk-step>

</ng-stepper>

</ng-template>








<ng-template #showDetail>
  <div class="modal-header" style="text-align: center;" >
    <h4 class="modal-title text-center" id="modal-basic-title">{{__('label_details')}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <tabset class="tabModal">
      <tab heading="{{__('ordre_paiement.detail')}}">
            <table style="width: 100%;">
              <tr>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.libelle')}}</td>
                <td class="tab-detail">{{ordre_paiement.libelle}}</td>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.mois')}}</td>
                <td class="tab-detail">{{ordre_paiement.moisDetail}}</td>
              </tr>
              <tr>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.numero_ordre')}}</td>
                <td class="tab-detail" >{{ordre_paiement.numero_ordre}}</td>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.categorie')}}</td>
                <td class="tab-detail">{{ordre_paiement.categorie}}</td>
              </tr>

              <tr>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.montant_global')}}</td>
                <td class="tab-detail" >{{formatNumber(ordre_paiement?.montant_global, ' ')}}</td>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.etablissement')}}</td>
                <td class="tab-detail">{{ordre_paiement.etablissement}}</td>
              </tr>
              <tr>
                
                <td class="tab-detail-title" *ngIf="ordre_paiement.nombre_mois != null" >{{__('ordre_paiement.nombre_mois')}}</td>
                <td class="tab-detail" *ngIf="ordre_paiement.nombre_mois != null">{{ ordre_paiement.nombre_mois }}</td>
                <td class="tab-detail-title"  *ngIf="ordre_paiement.type_etablissement == 1">{{__('ordre_paiement.equipement')}}</td>
                <td class="tab-detail" *ngIf="ordre_paiement.equipement != null && ordre_paiement.type_etablissement == 1">{{formatNumber(ordre_paiement.equipement, ' ')}}</td>
                <td class="tab-detail" *ngIf="ordre_paiement.equipement == null && ordre_paiement.type_etablissement == 1"> Sans équipement</td>
              </tr>

              <tr>
                <td class="tab-detail-title">{{__('ordre_paiement.date_debut_paiement')}}</td>
                <td class="tab-detail">{{ordre_paiement.date_debut_paiement | date : 'dd-MM-y'}}</td>
                <td class="tab-detail-title">{{__('ordre_paiement.date_fin_paiement')}}</td>
                <td class="tab-detail">{{ordre_paiement.date_fin_paiement | date : 'dd-MM-y' }}</td>
              </tr>

              
              <tr>
                <td class="tab-detail-title" nowrap>{{__('ordre_paiement.numero_ordre_tresor')}}</td>
                <td class="tab-detail" >{{ordre_paiement.id_ordre_ministere}}</td>
                <td class="tab-detail-title">{{__('table.statut')}}</td>
                <td class="tab-detail" style="color: #858584;" *ngIf="ordre_paiement.statut == -1">{{__('tooltip.brouillon')}}</td>
                <td class="tab-detail" style="color: #f5c421;" *ngIf="ordre_paiement.statut == 0">{{__('tooltip.attente')}}</td>
                <td class="tab-detail" style="color: #44a1a0;" *ngIf="ordre_paiement.statut == 1" >{{__('tooltip.valide')}}</td>
                <td class="tab-detail" style="color: #ff7467;" *ngIf="ordre_paiement.statut == 2" >{{__('tooltip.rejete')}}</td>
                <td class="tab-detail" style="color: blue;"  *ngIf="ordre_paiement.statut == 3" >{{__('ordre_paiement.envoie')}}</td>
                
              </tr>
              
            </table>
            <div class="row w-100 d-flex justify-content-end">
              <div class="col-lg-12">
                <button type="button" *ngIf="ordre_paiement.statut == -1" name="button" class="btn mb-4 button-control" (click)="getCreerFunction(ordre_paiement.id)">{{__('tooltip.cree')}}</button>            
              </div> 

              <div class="col-lg-12">
                <button type="button" *ngIf="ordre_paiement.statut == 0 && autority('ORP_5')" name="button" class="btn mb-4 button-control" (click)="getValiderFunction(ordre_paiement.id)">Valider</button>   
                <button type="button" *ngIf="ordre_paiement.statut == 0 && autority('ORP_5')" name="button" class="btn mb-4 button-cancel" (click)="getValRejFunction(ordre_paiement.id)">Réjeter</button>      
              </div> 

              <div class="col-lg-12">
                <button type="button" *ngIf="ordre_paiement.statut == 1 && autority('ORP_6')" name="button" class="btn mb-4 button-control" style="width: auto" (click)="envoiForm(ordre_paiement.id)">{{__('ordre_paiement.envoie_PAOMA')}}</button>            
              </div>  
          </div>
            <br>

      </tab>


      <tab heading="{{__('ordre_paiment.document')}}">

      
        <div class="row" style="margin-left: 20px;">
          <div class="col-lg-4">
            <label class="label-doc">{{__('etablissement.document')}} </label>
          </div>
        </div>

        <div class="row" style="margin-left: 20px;">
          <div class="col-lg-4" *ngFor="let document of documentsPJ;">
              <div class="row">
              <div class="col-lg-8 img-detail"  > 
                <a id="file_{{document.id}}" href="{{document.name}}" >
                  <img  [src]="document.name" *ngIf="document.name.split('.').pop()=='jpeg'||document.name.split('.').pop()=='png'||document.name.split('.').pop()=='jpg'||document.name.split('.').pop()=='svg'"  style="width:100%" >
                  <img  src="assets/img/pdf.jpeg" *ngIf="document.name.split('.').pop()=='pdf' " style="width:100%" >
                  <img  src="assets/img/doc.jpeg" *ngIf="document.name.split('.').pop()=='doc' || document.name.split('.').pop()=='docx' || document.name.split('.').pop()=='xlsx' " style="width:100%" >
                  <img  src="assets/img/txt.png" *ngIf="document.name.split('.').pop()=='txt'"   style="width:100%" >                     
                </a>
              </div>
              <div class="col-lg-4 img-detail" >
                <button #btnDownload tooltip="{{__('tooltip.download')}}" class="btn btn-sm btn-success" type="button" (click)="download(document)"  target="_self"><i class="fa fa-download fa-2x colorThemePrimary"></i></button>
              </div>
            </div>
          </div>
          
        

          
        </div>



        

       
      </tab>


      <tab heading="{{__('tooltip.title_bnf')}}">

       

          <div class="row" style="margin-left: 20px;">
            <div class=" w-100 d-flex justify-content-between"  style="margin: 20px 0">
              <h4 style="color: #44a1a0;">Liste bénéficiaire N° d'ordre: {{ordre_paiement.numero_ordre}} - {{ordre_paiement.etablissement}}</h4>
              <div class="export">
                <button class="px-2" (click)="exprotExcelDetailOR()">
                  {{__('ordre_paiement.export_excel')}}                 
                  <img class="mb-1" src="assets/img/excel.png" alt="">
                  </button>
                <button (click)="exportPdfDetailsOR()" class="ml-3 px-2"> {{__('ordre_paiement.export_pdf')}}  <img   class="mb-1" src="assets/img/pdf-file.png" alt=""> </button>
              </div>
            </div>

            <div class="row" style="text-align:center; width:100%" > 
                                  
              <div class="col-lg-12" >
                  <div class="form-group" style="text-align: center;" >
                    <div class="form-check form-check-inline mr-1" >
                      <input class="form-check-input" type="radio" [(ngModel)]="filtreStatus"  name="filtreStatus" (click)="getFiltreStatus('tout')" id="inlineRadio1" value="tout" >
                      <label class="form-check-label"  >{{__('tooltip.tout')}}</label>
                    </div>
    
                    <div class="form-check form-check-inline mr-1" >
                      <input class="form-check-input" type="radio" [(ngModel)]="filtreStatus"  name="filtreStatus" (click)="getFiltreStatus('1')" id="inlineRadio1" value="1" >
                      <label class="form-check-label"  >Payé</label>
                    </div>
                  
                    <div class="form-check form-check-inline mr-1" >
                      <input class="form-check-input" type="radio" [(ngModel)]="filtreStatus"  name="filtreStatus" (click)="getFiltreStatus('0')" id="inlineRadio1" value="0" >
                      <label class="form-check-label"  >En attente</label>
                    </div>
                    
                  </div>
               
                  
                </div>
             </div>

            <div class="col-lg-12">

                            <div class="row footer-paginate"  >
                              <div class="col-lg-4"> 
                                <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                                <select name="" id="" [(ngModel)]="detail_e_filtreSelect" (change)="detail_e_getFiltreSelect()" class="search-input" >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                  <option value="15000">15000</option>
                                  <option value="20000">20000</option>
                                  <option value="25000">25000</option>
                                  <option value="30000">30000</option>
                                  <option value="">Tout</option>
                                </select> 
                                <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                              </div>
                              
                  
                            </div>
                            <div class="text-center">
                              <span style="padding-right:50px" >{{__('ordre_paiement.nombre_beneficiaire')}} : <b >{{ formatNumber(totalDateinfullPage, ' ') }}</b > </span>
                                <span style="padding-right:50px" >{{__('ordre_paiement.montant_paositra')}} : <b >{{ formatNumber(montantGlobal?.montant_paositra, ' ') }} (Ar)</b >  </span>
                              <span style="padding-right:50px" >{{__('ordre_paiement.montant_e_poketra')}}: <b >{{ formatNumber(montantGlobal?.montant_e_poketra, ' ') }}  (Ar)</b >  </span>
                                
                        </div>
                            <br>
                            
              <table style="width: 100%"  class="table table-striped table-hover " >
                <thead >
                  <tr>
                    <th>{{__('carte.numcompte')}} </th>
                    <th>{{__('etudiant.code_nin')}} </th>
                    <th>{{__('etudiant.nom')}} </th>
                    <th>{{__('etudiant.prenom')}} </th>
                    <th>{{__('etudiant.telephone')}} </th>
                    <th *ngIf="ordre_paiement.type_etablissement == 1">{{__('etudiant.niveau')}} </th>
                    <th>{{__('ordre_paiement.type_compte')}} </th> 
                    <th nowrap class="text-right">{{__('ordre_paiement.montant_paye')}}  (Ar)</th>
                    <th nowrap class="text-center">{{__('table.statut')}} </th>
                    <th nowrap class="text-center">{{__('etudiant.cashout')}} </th>
                  </tr>
                </thead>
                <tbody *ngIf="this.detail_ordre_paiements?.length != 0" >
                  <tr *ngFor="let etudiant of detail_ordre_paiements">
                    <td>
                      {{etudiant.num_compte ? etudiant.num_compte :  __('carte.nondefini')}}
                    </td>
                    <td class="text-center">{{ etudiant?.matricule }}</td>
                    <td class="text-left">{{ etudiant?.nom }}</td>
                    <td class="text-left">{{ etudiant?.prenom }}</td>
                    <td class="text-left">{{ etudiant?.telephone }}</td>
                    <td class="text-left" *ngIf="ordre_paiement.type_etablissement == 1">{{ etudiant?.niveau }}</td>
                    <td class="text-center">
                      <span *ngIf="etudiant.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                      <span *ngIf="etudiant.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                  </td>
                    <td class="text-right">{{ formatNumber(etudiant?.montant, ' ') }}</td>
                    <td class="text-center">
                        <span *ngIf="etudiant.statut == 0" style="color: #f5c421;" > {{__('tooltip.attente')}}</span>  
                        <span *ngIf="etudiant.statut == 1" style="color: #44a1a0;" > {{__('tooltip.payee')}}</span>            
                        <span  *ngIf="etudiant.statut == -1" style="color: #f00b0b;"  >{{__('tooltip.echec')}}</span>  
                    </td> 
                    <td class="text-center">
                      <span *ngIf="etudiant.cashout == 0" style="color: #f00b0b;" > {{__('etudiant.non')}}</span>  
                      <span *ngIf="etudiant.cashout == 1" style="color: #44a1a0;" > {{__('etudiant.oui')}}</span>            
                  </td> 

                  </tr>
                  
                </tbody>
                <tbody *ngIf="detail_ordre_paiements?.length == 0">
                  <tr>
                      <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
                  </tr>
                </tbody> 
                          
              </table>


              <div class="row footer-paginate" >
                <div class="col-lg-4"> 
                  <span class="three-point" style="padding-left: 0px;" >{{paginate.e_infoData}}</span> 
                </div>
                <div class="col-lg-8 btn-paginate">
                  <button (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                  <button (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                  <button *ngIf="paginate.e_currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_firstPage)" > 1 </button>
                  <span *ngIf="paginate.e_currentPage > 3" class="three-point" > ... </span>
                  <button *ngIf="paginate.e_cPage_less_2 > 0 && paginate.e_currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_path_cPage_less_2)" > {{paginate.e_cPage_less_2}} </button>
                  <button *ngIf="paginate.e_cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_path_cPage_less_1)" > {{paginate.e_cPage_less_1}} </button>
                  <button (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{paginate.e_currentPage}} </button>
                  <button *ngIf="paginate.e_cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_path_cPage_more_1)" > {{paginate.e_cPage_more_1}} </button>
                  <button *ngIf="(paginate.e_cPage_more_2 > 0 && paginate.e_cPage_more_2 > paginate.e_lastNumPage && paginate.e_cPage_more_1 > paginate.e_lastNumPage && paginate.e_currentPage > paginate.e_lastNumPage) || paginate.e_cPage_more_2 == paginate.e_lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_path_cPage_more_2)" > {{paginate.e_cPage_more_2}} </button>
                  <span *ngIf="paginate.e_cPage_more_2 < paginate.e_lastNumPage && paginate.e_cPage_more_1 < paginate.e_lastNumPage && paginate.e_currentPage < paginate.e_lastNumPage" class="three-point" > ... </span>
                  <button *ngIf="paginate.e_cPage_more_2 < paginate.e_lastNumPage && paginate.e_cPage_more_1 < paginate.e_lastNumPage && paginate.e_currentPage < paginate.e_lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_lastPage)" > {{paginate.e_lastNumPage}} </button>
                  <button (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                  <button (click)="getUrlDatatableDetailePaiementEtudiant(paginate.e_lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                </div>
    
              </div>

                            
            </div>
          </div>
            
      </tab>
      <!-- <tab heading="{{__('ordre_paiement.resume')}}" *ngIf="ordre_paiement.type_etablissement == 1" (click)="listRecap()" >
            <div  id="pdfTable" #pdfTable>

              <div class="export w-100 d-flex justify-content-between"  style="margin: 20px 0">
                <h4 style="color: #44a1a0;">ETAT RECAPITULATIF DES BOURSIERS DE : {{ordre_paiement.etablissement}}</h4>
              </div>

              <b  style=" font-size:15px;font-style: italic;" > EQUIPEMENT + BOURSES {{ ordre_paiement.nombre_mois }} MOIS</b>  
                
                  <p style="text-align:center">  <b> N° ordre : </b>  {{ordre_paiement.numero_ordre}} &nbsp;&nbsp;&nbsp;&nbsp;
                    <span  *ngIf="ordre_paiement.statut == -1">  <b > Statut : </b> {{__('tooltip.brouillon')}}</span>  
                    <span  *ngIf="ordre_paiement.statut == 0" > <b> Statut :  </b> {{__('tooltip.attente')}}</span>  
                    <span  *ngIf="ordre_paiement.statut == 1" > <b> Statut :  </b> {{__('tooltip.valide')}}</span>  
                    <span  *ngIf="ordre_paiement.statut == 2" > <b> Statut :  </b> {{__('tooltip.rejete')}}</span>  
                    <span  *ngIf="ordre_paiement.statut == 3" > <b> Statut :  </b> {{__('ordre_paiement.envoie')}}</span>  
                  </p> 

              <table style="width: 100%;" cellpadding = "10"  >


                  <tr style="border: 1px solid #e3e3e3; color: #44a1a0 !important; font-weight: bold; text-align:center" >
                    <td class="tab-detail-title" rowspan="2">Etablissement</td>
                    <td class="tab-detail-title text-center"  colspan="2">L1</td>
                    <td class="tab-detail-title text-center" style=" padding:15px;" colspan="2">L2</td>
                    <td class="tab-detail-title text-center" colspan="2">L3</td>
                    <td class="tab-detail-title text-center" colspan="2">M1</td>
                    <td class="tab-detail-title text-center" colspan="2">M2</td>
                    <td class="tab-detail-title text-center" colspan="2">Doctorat</td>
                    <td class="tab-detail-title text-center" rowspan="2">TOTAL</td>
                  </tr>
                  <tr style="border: 1px solid #e3e3e3; color: #44a1a0 !important; font-weight: bold; text-align:center" >
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                    <td class="tab-detail-title text-center" >Passant</td>
                    <td class="tab-detail-title text-center" >Redoublant</td>
                  </tr>
                  <tr style="border: 1px solid #e3e3e3; text-align:center" >
                    <td class="tab-detail" >{{ordre_paiement.etablissement}}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL1_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL1_redoublant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL2_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL2_redoublant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL3_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbL3_redoublant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbM1_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbM1_redoublant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbM2_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbM2_redoublant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbDR_passant }}</td>
                    <td class="tab-detail text-center" >{{ nb_etudiant_par_niveau.nbDR_redoublant }}</td>
                    <td class="tab-detail text-center" > <b> {{ nb_etudiant_par_niveau.totalToutNiveau }}</b></td>
                  </tr>

                  <tr style="border: 1px solid #e3e3e3; text-align:center">
                    <td class="tab-detail" >Total</td>
                    <td class="tab-detail" colspan="2" >L1 : <b> {{ nb_etudiant_par_niveau.totalL1 }} </b></td>
                    <td class="tab-detail" colspan="2" >L2 : <b> {{ nb_etudiant_par_niveau.totalL2 }}</b></td>
                    <td class="tab-detail" colspan="2" >L3 : <b> {{ nb_etudiant_par_niveau.totalL3 }}</b></td>
                    <td class="tab-detail" colspan="2" >M1 : <b> {{ nb_etudiant_par_niveau.totalM1 }}</b></td>
                    <td class="tab-detail" colspan="2" >M2 : <b> {{ nb_etudiant_par_niveau.totalM2 }}</b></td>
                    <td class="tab-detail" colspan="2" >Doctorat : <b>  {{ nb_etudiant_par_niveau.totalDR }}</b></td>
                    <td class="tab-detail text-center" > <b> {{ nb_etudiant_par_niveau.totalToutNiveau }}</b></td>
                  </tr>


                  <tr style="border: 1px solid #e3e3e3; text-align:center">
                    <td colspan="14"></td>
                  </tr>

                
                  <tr style="border: 1px solid #e3e3e3; color: #44a1a0 !important; font-weight: bold; text-align:center" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td class="tab-detail-title" rowspan="2">Etablissement</td>
                    <td class="tab-detail-title text-center" colspan="2">L1</td>
                    <td class="tab-detail-title text-center" colspan="2">L2</td>
                    <td class="tab-detail-title text-center" colspan="2">L3</td>
                    <td class="tab-detail-title text-center" colspan="2">M1</td>
                    <td class="tab-detail-title text-center" colspan="2">M2</td>
                    <td class="tab-detail-title text-center" colspan="2">Doctorat</td>
                    <td class="tab-detail-title text-center" rowspan="2" nowrap >TOTAL  <br> (en Ariary) </td>
                  </tr>
                  <tr style="border: 1px solid #e3e3e3; color: #44a1a0 !important; font-weight: bold; text-align:center" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td class="tab-detail-title text-center" nowrap>Passant</td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant </td>
                    <td class="tab-detail-title text-center" nowrap  >Passant  </td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant  </td>
                    <td class="tab-detail-title text-center" nowrap>Passant </td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant  </td>
                    <td class="tab-detail-title text-center" nowrap>Passant </td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant  </td>
                    <td class="tab-detail-title text-center" nowrap>Passant </td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant  </td>
                    <td class="tab-detail-title text-center" nowrap>Passant </td>
                    <td class="tab-detail-title text-center" nowrap>Redoublant </td>
                  </tr>
                  <tr style="border: 1px solid #e3e3e3; text-align:right" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td class="tab-detail" style="text-align : center" >{{ordre_paiement.etablissement}}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL1_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL1_redoublant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL2_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL2_redoublant, ' ')}}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL3_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalL3_redoublant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalM1_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalM1_redoublant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalM2_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalM2_redoublant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalDR_passant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap >{{ formatNumber(bourse_par_nb_etudiant.montantTotalDR_redoublant, ' ') }}</td>
                    <td class="tab-detail text-right " nowrap > <b> {{ formatNumber(bourse_par_nb_etudiant.montantTotalToutNiveau, ' ') }}</b></td>
                  </tr>

                  <tr style="border: 1px solid #e3e3e3; text-align:right" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td class="tab-detail" style="text-align : center" >Bourse 1 mois</td>
                    <td class="tab-detail" colspan="2" >L1 : <b> {{ formatNumber(total_bourse_par_niveau.montantTotalL1, ' ') }} </b></td>
                    <td class="tab-detail" colspan="2" >L2 : <b> {{ formatNumber(total_bourse_par_niveau.montantTotalL2, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >L3 : <b> {{ formatNumber(total_bourse_par_niveau.montantTotalL3, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M1 : <b> {{ formatNumber(total_bourse_par_niveau.montantTotalM1, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M2 : <b> {{ formatNumber(total_bourse_par_niveau.montantTotalM2, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >Doctorat : <b>  {{ formatNumber(total_bourse_par_niveau.montantTotalDR, ' ') }}</b></td>
                    <td class="tab-detail text-right" > <b> {{ formatNumber(bourse_par_nb_etudiant.montantTotalToutNiveau, ' ') }}</b></td>
                  </tr>


                  <tr style="border: 1px solid #e3e3e3; text-align:center" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td colspan="14"></td>
                  </tr>

                  
                  <tr style="border: 1px solid #e3e3e3; text-align:right">
                    <td class="tab-detail" style="text-align : center" >Equipement</td>
                    <td class="tab-detail" colspan="2" >L1 : <b> {{ formatNumber(total_bourse_avec_prime.montantL1_avecPrime, ' ') }} </b></td>
                    <td class="tab-detail" colspan="2" >L2 : <b> {{ formatNumber(total_bourse_avec_prime.montantL2_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >L3 : <b> {{ formatNumber(total_bourse_avec_prime.montantL3_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M1 : <b> {{ formatNumber(total_bourse_avec_prime.montantM1_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M2 : <b> {{ formatNumber(total_bourse_avec_prime.montantM2_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >Doctorat : <b>  {{ formatNumber(total_bourse_avec_prime.montantDR_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail text-right" > <b> {{ formatNumber(total_bourse_avec_prime.montantTotalToutNiveau_avecPrime, ' ') }}</b></td>
                  </tr>


                  <tr style="border: 1px solid #e3e3e3; text-align:center" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td colspan="14"></td>
                  </tr>

                
                  <tr style="border: 1px solid #e3e3e3; text-align:right" *ngIf="ordre_paiement.nombre_mois != 0" >
                    <td class="tab-detail" style="text-align : center" >Bourse {{ ordre_paiement.nombre_mois }} mois + Equipement</td>
                    <td class="tab-detail" colspan="2" >L1 : <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantL1_nb_mois_avecPrime, ' ') }} </b></td>
                    <td class="tab-detail" colspan="2" >L2 : <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantL2_nb_mois_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >L3 : <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantL3_nb_mois_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M1 : <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantM1_nb_mois_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >M2 : <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantM2_nb_mois_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail" colspan="2" >Doctorat : <b>  {{ formatNumber(total_bourse_nb_mois_avec_prime.montantDR_nb_mois_avecPrime, ' ') }}</b></td>
                    <td class="tab-detail text-right" nowrap  > <b> {{ formatNumber(total_bourse_nb_mois_avec_prime.montantTotalToutNiveau_nb_mois_avecPrime, ' ') }}</b></td>
                  </tr>

            
          
              </table>
            </div>
            <br>

             <div class="row">
              <div class="col-lg-12">
                <button (click)="downloadAsPDF()" class="ml-3 px-2 mb-4" style="float: right;"> {{__('ordre_paiement.export_pdf')}}  <img   class="mb-1" src="assets/img/pdf-file.png" alt=""> </button>
              </div>  
          </div>


        </tab> -->

  </tabset>

  
</ng-template>



<ng-template #updatePieceJoint let-modal style="background-color: red; width: auto;">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{__('ordre_paiment.addPJ')}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">
      <br>
    <form ngNativeValidate (ngSubmit)="updatePJ()">
        <div class="row">
          
          <div class="col-lg-12" style="text-align:center">
            <label >
              <span *ngIf="submitted && f.document.errors" class="invalid-form-feedback">
                  <i class="fa fa-times-circle" *ngIf="f.document.errors.required"></i>
              </span>
              {{__('ordre_paiment.joint_fichier')}}
              </label>
            <span class="imgAdd" (click)="documentsPJ.length <= 4 ? addImage(documentFile) : false" tooltip="{{documentsPJ.length <= 4 ? __('tooltip.click_add') : __('tooltip.document_max')}}">
                <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
            </span>
            <br>
            
             <span class="imgUpload" *ngFor="let document of documentsPJ;let i  =index">
               <img  [src]="document.name" *ngIf="document.name.split('.').pop()=='jpeg'||document.name.split('.').pop()=='png'||document.name.split('.').pop()=='jpg'||document.name.split('.').pop()=='svg' || document.name.substr(0, 10) == 'data:image'"  alt="{{'Document '+(i +1)}}" style="height: 150px;" >
               <img  src="assets/img/pdf.jpeg" *ngIf="document.name.split('.').pop()=='pdf' || document.name.substr(0, 20) == 'data:application/pdf'"  alt="{{'Document '+(i +1)}}" style="height: 150px;" >
               <img  src="assets/img/doc.jpeg" *ngIf="document.name.split('.').pop()=='doc' || document.name.split('.').pop()=='docx' || document.name.split('.').pop()=='xlsx' || document.name.substr(0, 20) == 'data:application/vnd'"  alt="{{'Document '+(i +1)}}" style="height: 150px;" >
               <img  src="assets/img/txt.png" *ngIf="document.name.split('.').pop()=='txt' || document.name.substr(0, 15) == 'data:text/plain'"  alt="{{'Document '+(i +1)}}" style="height: 150px;" >
               <i #img  tooltip="{{__('tooltip.click_delete')}}" (click)="deleteImg(document,img)" style="margin-right: 30px; margin-left: 10px"  class="fa fa-trash"></i>
             </span>
             
             <input 
              (change)="handleInputChangePJ($event)" 
              formControlName="document" 
              #documentFile 
              style="display: none"  
              id="document" 
              name="document" 
              type="file" 
              class="file" 
              multiple >
          </div>

      
          
        </div>
      <br><br>
        

      
      <div class="row">
        <div class="col-lg-12">
          <button type="submit" name="button" class="btn mb-4 button-control" > {{__('tooltip.validate')}}</button>            
          <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
      </div>      
      </div>
  </form>

  </div>
  
  
</ng-template>


