<br><div class="repMenu">
    <div class="row  d-flex  justify-content-start">     
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/DAS']" routerLinkActive="active">Tableau de bord</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/accueil/dash']"  routerLinkActive="active">Dashboard</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourse']"  routerLinkActive="active">{{__('resume.etat_res_bourse')}}</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourseUniversite']"  routerLinkActive="active">{{__('resume.etat_res_bourse_univ')}}</a>
      </div>
  </div>
  </div> 
  <div class=" bg-white p-2  repMenu mt-2 w-100">
    <div class="export">
      <button class="px-2" (click)="exportToExcel()">
       Export excel
       <img class="mb-1" src="assets/img/excel.png" alt="">
   
   
       </button>
       <button (click)="exportPdf()" class="ml-3 px-2">
         Export Pdf
         <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
   
         </button>
     </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      
      <br>

      <div class="card">
        
        <div class="card-body">


          <div class="row footer-paginate d-flex align-items-center" >
            <div class="col-lg-2"> 
              <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
              <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="5000">5000</option>
                <option value="10000">10000</option>
              </select> 
              <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
            </div>
            <div class="col-md-8 row d-flex align-items-center justify-content-center" >
                <div class="form-group col-md-4 ">
                  <label >Date début ordre paiement (<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateDebut"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input  type="date" class="form-control" [(ngModel)]="dateDebut"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
                <div class="form-group col-md-4">
                  <label >Date fin ordre paiement(<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateFin"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input   type="date" class="form-control" [(ngModel)]="dateFin"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
              <div>
                <button [disabled]="!dateDebut || !dateFin" style="height: 44px;" type="button" (click)="filtreByDate()" name="button" class="mt-2 btn button-control" >{{__('btn.filtre')}}</button>       

              </div>
            </div>
            <div class="col-lg-2 btn-paginate">
              <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
            </div>

          </div>

          <table class="table table-hover table-striped" style="width: 100%;" >
            <thead>
              <tr>
                <th class="text-center">{{__('dashboard.etab')}} <br>

                  <i class="fa fa-caret-down icon-tri" (click)="triTable('etablissement.name','asc')" *ngIf="tri=='etablissement.name' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('etablissement.name','desc')"  *ngIf="tri=='etablissement.name' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('etablissement.name','asc')" *ngIf="tri!='etablissement.name'" ></i> 
                  <input type="text" #name class="inputCol" name="name" (keyup.enter)="getUrlDatatable(endPOint, 'etablissement.name',name, 'ordre_paiement')" id="">

                </th>

                <th class="text-center">{{__('dashboard.nb_beneficiaire')}} <br>
                </th>
                <th class="text-center">{{__('dashboard.nb_cart')}} <br>
                </th>
                <th class="text-center">{{__('dashboard.nb_ordre_p')}} <br>
                </th>
                <th class="text-center">{{__('dashboard.nb_crediter')}}  <br>
                </th>
                <th class="text-center">{{__('dashboard.nb_non_crediter')}} <br>
                </th>
                <th class="text-center">{{__('dashboard.nb_bourse_retirer')}} <br>
                </th>
              
              
              </tr>
        
            </thead>
            <tbody *ngIf="ordre_paiements?.length != 0" class="body-paginate-hr" >
               <tr *ngFor="let ordre_paiement of ordre_paiements">
                <td class="text-center">{{ ordre_paiement?.Minister }}</td>
                <td class="text-center">{{ ordre_paiement?.Nombre_concerne }}</td>
                <td class="text-center">{{ ordre_paiement?.Nombre_carte }}</td>
                <td class="text-center">{{ ordre_paiement?.Nombre_ordre_paiement }}</td>
                <td class="text-center">{{ ordre_paiement?.Nombre_paiement }}</td>

                <td class="text-center">{{ ordre_paiement?.Nombre_paiement_a_faire }}</td>
                <td class="text-center">{{ ordre_paiement?.Nombre_cashout }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="ordre_paiements?.length == 0">
            <tr>
                <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
        
          </table>

         <!--  <div class="row footer-paginate" >
            <div class="col-lg-4"> 
              <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
            </div>
            <div class="col-lg-8 btn-paginate">
              <button (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
              <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
              <span *ngIf="currentPage > 3" class="three-point" > ... </span>
              <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
              <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
              <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
              <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
              <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
              <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
              <button (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
              <button (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
            </div>

          </div> -->

        </div>
      
      </div>
    </div>

  </div>

     <!--/.row [formGroup]="registerFormSelect" -->
     <div class="row">
      <div class="col-lg-12">
        <br>
        <div class="card">
          <div class="card-body">
            <form ngNativeValidate  (ngSubmit)="addSelectForm()">

              <div class="row">

                <div class="col-lg-5">
                  <div class="form-group">
                    <label >{{__('carte.ministere')}} </label>
                    <ng-select placeholder="{{__('carte.ministere')}}"
                        [(ngModel)]="filtre_etablissement_id" 
                        class="form-control"
                        name="etablissement"
                        [readonly]="superAdmin == 0"
                        (change)="getCategorieByIdEtab($event,categorieEl)"
                        >
                        <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                    </ng-select> 
                    </div>
                </div>
          
                <div class="col-lg-5">
                  <div class="form-group">
                    <label >{{__('concerner.categorie_concerne_id')}} </label>
                    <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                        [(ngModel)]="filtre_categorie_concernees_id" 
                        class="form-control"
                        #categorieEl
                        >
                        <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
                    </ng-select> 
                    </div>
                </div> 

                <div class="col-lg-2 d-flex align-items-center mt-1" >
                    <button style="height: 44px;" type="submit" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>       
                </div>

            </div>
            </form>
          </div>
        </div>
      </div>
    </div> 
     
    <span class="title-card">{{__('dashboard.recapitulatif_preinscription_a_valider_par_genre')}}</span>

      <div class="row">
        <div class=" mx-2 bg-white p-2  repMenu mt-2  w-100">
          <div class="export">
            <button class="px-2" (click)="exportToExcelRecap()">
             Export excel
             <img class="mb-1" src="assets/img/excel.png" alt="">
         
         
             </button>
             <button (click)="exportPdfRecap()" class="ml-3 px-2">
               Export Pdf
               <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
         
               </button>
           </div>
        </div>
        <div class="col-lg-12">
          <br>
          <div class="card">
            <div class="card-body border-card-green height">
              <div class="row">
            
                  <table  class="table table-hover table-striped" style="width: 100%;">
                    <thead>
                      <tr>
                        <th class="text-center">{{__('dashboard.etablissement')}}</th>
                        <th class="text-center">Nombre</th>

                        <th class="text-center">avec cartes</th>
                        <th class="text-center">sans cartes</th>

                        <th class="text-center">avec cartes active</th>
                        <th class="text-center">avec cartes inactive</th>

                        <!-- <th class="text-center">bénéficiaires activés</th>
                        <th class="text-center">bénéficiaires desactivés</th> -->
                      </tr>
                    </thead>
                    <tbody *ngIf="recapitulatifs?.length != 0" >
                        <tr *ngFor="let rec of recapitulatifs">
                        <td class="text-left">{{ rec?.etablissement_name }}</td>   
                        <td class="text-center">{{ formatNumber(rec?.nombre_concernees,' ')  }}</td>      

                        <td class="text-center">{{ formatNumber(rec?.concernees_avec_carte, '') }}</td>   
                        <td class="text-center">{{ formatNumber(rec?.concernees_sans_carte,' ')  }}</td> 

                        <td class="text-center">{{ formatNumber(rec?.concernees_avec_carte_active,' ')  }}</td>  
                        <td class="text-center">{{ formatNumber(rec?.concernees_avec_carte_inactive, '') }}</td>   
<!-- 
                        <td class="text-center">{{ formatNumber(rec?.concernees_active, '') }}</td>   
                        <td class="text-center">{{ formatNumber(rec?.concernees_desactive,' ')  }}</td>              -->
                        </tr>
                    </tbody>
                    <tbody *ngIf="recapitulatifs?.length == 0">
                    <tr>
                      <td colspan="8" class="text-center" style="width: 100%;">{{__('label_no_data')}}</td>
                    </tr>
                    <tbody> 
      
                  </table>
              </div>
              
    
            </div>
          
          </div>
        </div>
  
      </div>
    <!--/.row-->


    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    