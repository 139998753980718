export class categorie {
    id: number ;
    name: string ;
    state: number ;
//   etablissement_id: any;
//   etablissement:any;
}

export class carte {
    id: number ;
    etablissement_id: number ;
    niveau: string ;
    numero_serie: string ;
    documents : any | [];
    active: number ;
    state: number ;
    affected: number;
    situation: number;
  concerne_id: any;
}

export class concerner {
    id: number ;
    concernees_id: number ;
    etab_id:number;
    nom: string ;
    prenom: string ;
    matricule: string ;
    sexe:string;
    type_piece:string;
    numero_piece: string ;
    date_livrance: string ;
    telephone: string ;
    email: string ;
    adresse: string ;
    date_naissance: string ; 
    code_postal: string ;
    active:string;
    documents : any | [];
    etablissement_id: number ;
    province_id: number ;
    categorie_concerne_id: number ;
    carte_numero_serie : any | null;
    categorie : string;
    etablissement_name : string;
    province : string;
    photo: string;
    state: number ;
    carte_situation: number | null;
    num_compte : number | null;

}

export class journalbord {
    id:number;
    action : string;
    date: string;
    l_name:string;
    f_name:string;
}
export class categoriEtablissement {
    etablissement_id : any;
    categorie_concernee_id:any;
    state : number;
}