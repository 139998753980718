

<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">    
        <div class="breadcrumb flat">
            <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
            <a [routerLink]="['/app/module/ADM']" routerLinkActive="active">Administration</a>
            <a [routerLink]="['/dashboard/ADM/plateforme/etablissement']"  routerLinkActive="active" *ngIf="autority('ETA_1')">Gestion ministères</a>
            <a [routerLink]="['/dashboard/ADM/administration/user']"  routerLinkActive="active" *ngIf="autority('USR_1')"> Gestion utilisateurs</a>
            <a [routerLink]="['/dashboard/ADM/administration/profil']"  routerLinkActive="active"  *ngIf="autority('PRFL_1')">Gestion profils</a>
            <a [routerLink]="['/dashboard/ADM/administration/module']"  routerLinkActive="active" *ngIf="autority('MDL_1')">Modules</a>
            <a [routerLink]="['/dashboard/ADM/administration/sousmodule']"  routerLinkActive="active" *ngIf="autority('SMDL_1')">Sous modules</a>
            <a [routerLink]="['/dashboard/ADM/plateforme/province']"  routerLinkActive="active" *ngIf="autority('PRV_1')">Province</a>
          </div>
    </div>
</div> 
<div  [ngClass]="{
    'p-2  repMenu mt-2 d-flex justify-content-between align-items-center': true,
    'bg-white': autority('USR_2')
}">
<div class="export">
    <button class="px-2" (click)="exportUserToExcel()">
     {{__('export.excel')}}
     <img class="mb-1" src="assets/img/excel.png" alt="">
     </button>
     <button (click)="exportUserToPdf()" class="ml-3 px-2">
       {{__('export.pdf')}}
       <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
  
       </button>
   </div>
    <div class="d-flex align-items-center">
        <button class="button-control" *ngIf="autority('USR_2')"  #addBtnUser (click)="openModal(addUser,null,addBtnUser)" ><i class="fa fa-plus"></i>{{__('civil.btn_add')}}</button>
    </div>
  </div>
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions"  style="width: 100%" class="table table-striped table-hover " >
            <thead>
              <tr>
                <th>{{__('civil.f_name')}}</th>
                <th class="text-center">{{__('civil.l_name')}}</th>
                <th class="text-center">{{__('civil.phone')}}</th>
                <th class="text-center">{{__('civil.email')}}</th>
                <th class="text-center">{{__('civil.etablissement')}}</th>
                <th class="actionTab text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="users?.length != 0" >
                <tr *ngFor="let user of users">
                <td>{{ user.f_name }}</td>
                <td >{{ user?.l_name }}</td>
                <td class="text-center">{{ user?.phone }}</td>
                <td >{{ user?.email }}</td>
                <td class="text-center">{{ user?.etablissement?.name }}</td>
                <td class="text-center">
                    <i class="fa fa-eye icon-table vert" ngbTooltip="{{__('tooltip.details')}}" #openModalDetail (click)="openModal(showDetail,user,openModalDetail)" ></i>
                    <i class="fa fa-edit icon-table" *ngIf="autority('USR_3')"   ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(addUser,user,openModalBtnEdit)" ></i>
                    <i class="fa fa-toggle-on icon-table vert"  ngbTooltip="{{__('tooltip.desactiver')}}"  #buttonElement (click)="toggleState(user,0,buttonElement)" *ngIf="user.state == 1 && autority('USR_6')" ></i>
                    <i class="fa fa-toggle-off icon-table vert"  ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(user,1,buttonElement)" *ngIf="user.state == 0 && autority('USR_6')" ></i>
                    <i class="fa fa-refresh icon-table" *ngIf="autority('USR_7')"   ngbTooltip="{{__('tooltip.regener_password')}}" #elementRefresh (click)="refreshPassword(user, elementRefresh)" ></i>
                    <i class="fa fa-trash icon-table red" *ngIf="autority('USR_4')"    ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(user,openModalBtnDelete)"></i>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="users?.length == 0">
            <tr>
                <td colspan="5" >{{__('label_no_data')}}</td>
            </tr>
        </tbody> 
          </table>
        </div>
      </div>
    </div>
  </div>
     
  <ng-template #addUser let-modal style="background-color: red; width: 1000px;">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addUserForm()">
            <div class="row">
                <div class="col-lg-6"> 
                    <div class="form-group">
                        <label >
                            {{__('civil.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.etablissment.errors" class="invalid-form-feedback">
                                <span *ngIf="f.etablissment.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <ng-select 
                        aria-readonly=""
                            formControlName="etablissment" 
                            [(ngModel)]="user.etablissement_id" 
                            class="form-control"
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissment.errors }"
                            [readonly]="superAdmin == 0 && activeEtabId != 1"
                            >
                            <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-6"> 
                    <div class="form-group">
                        <label >
                            {{__('civil.profil')}} (<span class="required-red" >{{__('label.require')}}</span>) 
                            <span *ngIf="submitted && f.profil.errors" class="invalid-form-feedback">
                                <span *ngIf="f.profil.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <ng-select 
                            formControlName="profil" 
                            [(ngModel)]="user.profil_id" 
                            class="form-control"
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.profil.errors }"
                            >
                            <ng-option 
                                [selected]="user?.profil_id != null "  
                                value="">{{__('civil.select_profil')}}</ng-option>
                            <ng-option  *ngFor="let profil of profils" [value]="profil?.id ">{{ profil?.name }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-6"> 
                    <div class="form-group">
                        <label >
                            {{__('civil.l_name')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.nom.errors" class="invalid-form-feedback">
                                <span *ngIf="f.nom.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <input  
                            formControlName="nom" 
                            type="text" 
                            name="l_name" 
                            id="l_name" 
                            [(ngModel)]="user.l_name" 
                            class="form-control"  
                            placeholder="{{__('civil.l_name')}}" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.nom.errors }"
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label >
                            {{__('civil.f_name')}} (<span class="required-red" >{{__('label.require')}}</span>) 
                            <span *ngIf="submitted && f.prenom.errors" class="invalid-form-feedback">
                                <span *ngIf="f.prenom.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <input 
                            formControlName="prenom" 
                            type="text" 
                            class="form-control" 
                            name="f_name" 
                            id="f_name" 
                            [(ngModel)]="user.f_name"  
                            placeholder="{{__('civil.f_name')}}" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.prenom.errors }"
                        >
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label >
                            {{__('civil.phone')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.phone.errors" class="invalid-form-feedback">
                                <span *ngIf="f.phone.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <input 
                            formControlName="phone" 
                            #element type="text" 
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            ng2TelInput   
                            onKeyPress="if(this.value.length==10) return false;"
                            (intlTelInputObject)="telInputObject($event)" 
                            (countryChange)="onCountryChange($event)"
                            [ng2TelInputOptions]="{initialCountry: currenCode,preferredCountries:['mg','sn']}"
                            [(ngModel)]="user.phone"  
                            (focus)="controle(element)" 
                            (input)="controle(element)"     
                            name="phone" 
                            id="phone" 
                            class="form-control" 
                            placeholder="03X XX XXX XX" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.phone.errors }"
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label >
                            {{__('civil.email')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.email.errors" class="invalid-form-feedback">
                                <span *ngIf="f.email.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <input 
                            formControlName="email" 
                            type="text" 
                            title="{{__('tooltip.email')}}" 
                            pattern="{{partener}}" 
                            name="email" 
                            id="email" 
                            [(ngModel)]="user.email" 
                            class="form-control"  
                            placeholder="{{__('civil.email')}}"
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.email.errors }"
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label >
                            {{__('civil.address')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.adress.errors" class="invalid-form-feedback">
                                <span *ngIf="f.adress.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <textarea 
                            formControlName="adress" 
                            name="address" 
                            id="address" 
                            [(ngModel)]="user.address" 
                            class="form-control"  
                            placeholder="{{__('civil.address')}}" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.adress.errors }"
                        >
                    </textarea>
                    </div>
                </div>  
                <div class="col-lg-6">
                    <label >
                      <span *ngIf="submitted && f.photo.errors" class="invalid-form-feedback">
                          <i class="fa fa-times-circle" *ngIf="f.photo.errors.required"></i>
                      </span>
                      {{__('civil.photo')}}
                    </label>
                    <span 
                      class="imgAdd"
                      (click)="documentLogo.length <= 4 ? addImage(documentFileLogo) : false" 
                      tooltip="{{documentLogo.length <= 4 ? __('tooltip.click_add') : __('tooltip.document_max')}}">
                        <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
                    </span>
                    <br>
                    <span class="imgUpload">
                      <img  [src]="documentLogo.name"style="height: 50px;" >
                    </span>
                     <input accept="image/*" formControlName="photo" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple > 
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"  >{{__('tooltip.enreg')}}</button>            
                    <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>            
                    <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
              </div> 
            </div>
        </form>
    </div>
</ng-template>

<ng-template #showDetail let-modal >
    <div class="modal-header" style="text-align: center;" >
        <h4 class="modal-title text-center" id="modal-basic-title">{{__('label_details')}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <tabset>
            <tab heading="{{__('civil.info_user')}}">
                <div class="row" style="margin-right:0;margin-left:0;margin-top: 2rem;margin-bottom: 2rem;">
                    <div class="col-lg-5" style="text-align: center!important;">
                        <span class="title-card-profil padding-50px  "><i class="fa fa-image"></i>Photo de profil</span>
                        <br><br><br><br>
                        <span class="imgUpload" >
                            <img  [src]="user.avatar"style="width 200px !important; max-height:200px;max-width: 260px;" >
                        </span>
                        <hr style="border: 1px solid #44a1a0;width: 150px;">
                        <br><br>
                    </div>
                    <div class="col-lg-6">
                        <div class="row" >
                            <div class="col-lg-4">
                                <h6>Ministère</h6>
                                <p class="data-info">{{user.etablissement?.name}}</p>
                            </div>
                            <div class="col-lg-4">
                                <h6>{{__('civil.profil')}}</h6>
                                <p class="data-info">{{user.profil?.name}}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row" >
                            <div class="col-lg-4">
                                <h6>{{__('civil.f_name')}}</h6>
                                <p class="data-info">{{ user?.f_name }}</p>
                            </div>
                            <div class="col-lg-4">
                                <h6>{{__('civil.l_name')}}</h6>
                                <p class="data-info"> {{ user?.l_name }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-4">
                                <h6>{{__('civil.adress')}}</h6>
                                <p class="data-info">{{ user?.address }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-4">
                                <h6>{{__('civil.phone')}}</h6>
                                <p class="data-info">{{ user?.phone }}</p>
                            </div>
                            <div class="col-lg-4">
                                <h6>{{__('civil.email')}}</h6>
                                <p class="data-info">{{ user?.email }}</p>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
</ng-template>
