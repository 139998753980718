<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">     
        <div class="breadcrumb flat">
            <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
            <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>       
            <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
   
          </div>


</div>
</div> 
<div *ngIf="autority('ENC_2')" class="bg-white p-2  repMenu mt-2 d-flex justify-content-end align-items-center">
  <div class="d-flex align-items-center">
    <button type="button" class="button-control ng-star-inserted" (click)="openModalEnrolementMultiple(mutlipleEnrolementElement)"><i class="fa fa-file-excel-o"></i> Enrôlement multiple</button>
       
  </div>
   
</div>
<div class="bg-white my-3 py-3">
    <div class="container">
        <form  (ngSubmit)="searchCarte()" class="card" ngNativeValidate> 
            <div class="card-body">
                <div class="row d-flex flex-column align-items-center">

                    <div class="col-lg-6" *ngIf="superAdmin == 1">
                        <div class="form-group">
                            <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                <span class="required-red" *ngIf="!etablissementId && submitted">/ Ce champ est obligatoire</span>
                                <!-- <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                                <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                                </span> -->
                            </label>
                            <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                            aria-readonly=""
                                [(ngModel)]="etablissementId" 
                                class="form-control"
                                [readonly]="superAdmin == 0 && activeEtabId != 1"
                                >
                                <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                            </ng-select>
                
                            <!--  <input formControlName="etablissement_id"  type="number" class="form-control" [(ngModel)]="concerner.etablissement_id" id="etablissement_id" placeholder="{{__('concerner.etablissement_id')}}" > -->
                        </div>
                        </div>
                    <div class="col-md-6 form-group ">
                        <label for="">Numero de serie</label>
                        <input required  min="0" type="number"  [(ngModel)]="numero_serie" class="form-control" name="numero_serie"  id="">
                        <div class="w-100 d-flex justify-content-center">
                            <button type="submit" style="height: 40px;" class="button-control ng-star-inserted mt-2">Rechercher</button>
                            <!-- <button (click)="searchCarte()">test</button> -->
                        </div>
                    </div>
                </div>
          <!-- <hr> -->
                <!-- <div *ngIf="carteArray.length == 0 && searchNumSerieSubmitted" style="text-align: center;">
                    <div class="row d-flex flex-column align-items-center" style="text-align:center">
                        <div class="col-12">
                            <img src="assets/img/empty-set.png" style="width: 5%;" alt="">
                        
                            <h3 class="mt-2">Aucune carte correspondant</h3>
                        </div>
                    </div>
                </div> -->
                <div class="container mt-2"  >
                    <hr>
                    <div class="alert alert-danger text-center" *ngIf="carteArray.length == 0 && searchNumSerieSubmitted && step2Carte">
                        <i class="fa fa-times-circle text-danger"></i>
                        Ce carte n'appartient pas dans  {{ superAdmin == 1 ? "le ministère séléctionné" : "votre ministère" }}
                    </div>
                    <div class="alert alert-danger text-center " *ngIf="numeroSerieExistInEtab == false && searchNumSerieSubmitted">
                        <i class="fa fa-times-circle text-danger"></i>
                        Carte introuvable
                    </div>
                </div>
                <div *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected == 0" style="text-align: center;">
                    <div class="alert alert-danger">
                        On ne peut pas enroler cette carte.La carte n'est pas affécté à une bénéficiaire
                    </div>
                </div>
                <div  class="alert alert-danger" *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected != 0 && carteArray[0].situation == 0" style="text-align: center;">
                    On ne peut pas enroler cette carte.La carte est non personnalisée 
                </div>
                <div *ngIf="carteArray.length != 0 && carteArray[0].affected != 0 && carteArray[0].situation == 4 &&  carteArray[0].active == 1 && searchNumSerieSubmitted" style="text-align: center;">
                    <div class="alert alert-warning">
                        <i class="fa fa-warning"></i>
                       La carte est déjà enrolée
                      
                    </div>
                </div>
                <ng-container *ngIf="carteArray.length != 0 && carteArray[0].affected != 0 && carteArray[0].situation == 3 &&  carteArray[0].active == 0 && searchNumSerieSubmitted">
                    <div  style="text-align: center;">
                        <div class="row" style="text-align:center">
                            <div class="col-12">
                                <i class="fa fa-user" style="font-size:4rem;color: #45a1a1;"></i>
                                <h3>Information sur le bénéficiaire</h3>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-lg-3">
                              <h6 class="title-info">Matricule</h6>
                              <p class="data-info">{{ carteArray[0]?.matricule}}</p>
                          </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Nom & prénom</h6>
                                <p class="data-info">{{ carteArray[0]?.prenom }} {{ carteArray[0]?.nom }}</p>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Niveau</h6>
                                <p class="data-info">{{ carteArray[0]?.niveau}}</p>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Téléphone</h6>
                                <p class="data-info">+{{ carteArray[0]?.telephone}}</p>
                            </div>
                        
                        </div>
                        <hr>               
                        
                       </div>
                       <div  style="text-align: center;">
                          <div class="row" style="text-align:center">
                              <div class="col-12">
                                  <i class="fa fa-credit-card" style="font-size:4rem;color: #45a1a1;"></i>
                                  <h3>Information sur la carte</h3>
                              </div>
                          </div>
                          <hr>
                          <div class="row">
                              <div class="col-lg-3">
                                  <h6 class="title-info">Numéro de série</h6>
                                  <p class="data-info">{{ carteArray[0]?.numero_serie }}</p>
                              </div>
                             
                              <div class="col-lg-3">
                                  <h6 class="title-info">Ministère</h6>
                                  <p class="data-info">{{ carteArray[0]?.name}}</p>
                              
                              </div>
                              <div class="col-lg-3">
                                  <h6 class="title-info">Situation</h6>
                                  <p class="data-info">{{ situationCarte(carteArray[0]?.situation)}}</p>
                              
                              </div>
                              <div class="col-lg-3">
                                <h6 class="title-info">État</h6>
                                <p class="data-info">{{ carteArray[0]?.active == 0 ?  'Désactivé' : 'Activé'}}</p>
                            </div>
                          </div>
                          <hr>               
                          <div class="row d-flex justify-content-center" >
                              <button type="button" class="button-control ng-star-inserted" *ngIf="autority('GCA_11')" (click)="enrolerCarte(carteArray[0].numero_serie)">Enroler carte</button>
                              <div class="alert alert-danger" *ngIf="!autority('GCA_11')">
                                Vous n'avez pas le droit d'enroler cette carte
                             </div>
                              <!-- <div *ngIf="carteArray[0].active == 0 && !autority('GCA_8') || carteArray[0].active == 1 && !autority('GCA_9')" style="text-align: center;">
                                <div class="alert alert-danger">
                                   {{ carteArray[0].active == 0 ? "Vous n'avez pas le droit d'activer cette carte"  : "Vous n'avez pas le droit de désactiver cette carte"}}
                                </div>
                            </div> -->
                          </div>
                         </div>
                </ng-container>
          
            </div>
          </form>
        
    </div>

    
</div>

<ng-template #mutlipleEnrolementElement >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Enrôlement multiple</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">x</span>
      </button>
    </div>
  
    <form  >
  
      <div class="modal-body pt-3">
        <ng-stepper #cdkStepper [linear]="true">
 
            <cdk-step [stepControl]="true"   [optional]="false">
              <ng-template cdkStepLabel>
                <div class="step-bullet">1</div>
                <div class="step-title">Liste des cartes</div>
              </ng-template>
                    <div class="row">
                        <div class="card-body">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <br>
                                      <div class="card">
                                        <div class="card-body">
                                              <div class="row">
                                            
                                                <div class="col-lg-5">
                                                  <div class="form-group">
                                                    <label >{{__('bachelier.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                                      <span class="required-red" *ngIf="!etablissmentIdModal && submitFiltre">/ Ce champ est obligatoire</span>
                                                    </label>
                                                    <ng-select placeholder="{{__('concerner.etablissement_id')}}" 
                                                        [(ngModel)]="etablissmentIdModal" 
                                                        class="form-control"
                                                        name="etablissement"
                                                        [readonly]="superAdmin == 0 && activeEtabId != 1"
                                                        (change)="getCategorieByIdEtab($event,categorieEl)"
                                                        >
                                                        <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                                                    </ng-select> 
                                                    </div>
                                                </div>
                                            
                                                <div class="col-lg-5">
                                                  <div class="form-group">
                                                    <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                                      <span class="required-red" *ngIf="!categorieId && submitFiltre">/ Ce champ est obligatoire</span>
                                                    </label>
                                                    <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                                                        [(ngModel)]="categorieId" 
                                                        class="form-control"
                                                        #categorieEl
                                                        >
                                                        <ng-option  *ngFor="let categ of categories" [value]="categ?.categorie_concernee_id">{{ categ?.name }}</ng-option>
                                                    </ng-select> 
                                                    </div>
                                                </div> 
                                            
                                            
                                                <div class="col-lg-2 d-flex align-items-center mt-1" >
                                                    <button [disabled]="!etablissmentIdModal || !categorieId" (click)="getResultFiltred()" style="height: 44px;" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>       
                                                </div>
                                            
                                              </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  
                            </div>
                        
                            <div class="row footer-paginate" >
                              <div class="col-lg-4"> 
                                <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                                <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                </select> 
                                <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                              </div>
                              <div class="col-lg-8 btn-paginate">
                                <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
                              </div> 
                    
                            </div>
                              <table style="width: 100%"  class="table table-striped table-hover " >
                              <thead>
                                <tr>
                    
                                
                    
                    
                                  <th class="text-left">{{__('concerner.matricule')}}
                    
                                    <br>
                                      <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri=='concernees.matricule' && order=='desc'" ></i> 
                                      <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.matricule ','desc')"  *ngIf="tri=='concernees.matricule' && order=='asc'"  ></i> 
                                      <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri!='concernees.matricule'" ></i> 
                                      <input type="text" #matricule class="inputCol" name="matricule" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.matricule',matricule, false)" id="">
                                  </th>
                                  
                    
                                  <th class="text-left">{{__('concerner.nom')}}
                                    <br>
                                    <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri=='concernees.nom' && order=='desc'" ></i> 
                                    <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.nom','desc')"  *ngIf="tri=='concernees.nom' && order=='asc'"  ></i> 
                                    <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.nom','_asc')" *ngIf="tri!='concernees.nom'" ></i> 
                                    <input type="text" #nom class="inputCol" name="nom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.nom',nom, false)" id="">
                                  </th>
                    
                                  <th class="text-left">{{__('concerner.prenom')}}
                                    <br>
                                    <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.prenom','asc')" *ngIf="tri=='concernees.prenom' && order=='desc'" ></i> 
                                    <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.prenom','desc')"  *ngIf="tri=='concernees.prenom' && order=='asc'"  ></i> 
                                    <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.prenom','_asc')" *ngIf="tri!='concernees.prenom'" ></i> 
                                    <input type="text" #prenom class="inputCol" name="prenom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.prenom',prenom, false)" id="">
                                  </th>
                    
                                  <th class="text-left">{{__('concerner.telephone')}}
                                    <br>
                                    <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri=='concernees.telephone' && order=='desc'" ></i> 
                                    <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.telephone','desc')"  *ngIf="tri=='concernees.telephone' && order=='asc'"  ></i> 
                                    <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri!='concernees.telephone'" ></i> 
                                    <input type="text" #telephone class="inputCol" name="email" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.telephone',telephone, false)" id="">
                                  </th>

                                  <th class="text-left">Numéro série carte
                                    <br>
                                    <i class="fa fa-caret-down icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri=='categorie_concernees.name' && order=='desc'" ></i> 
                                    <i class="fa fa-caret-up icon-tri" (click)="triTable('categorie_concernees.name','desc')"  *ngIf="tri=='categorie_concernees.name' && order=='asc'"  ></i> 
                                    <i class="fa fa-caret-left icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri!='categorie_concernees.name'" ></i> 
                                    <input type="text" #categorie class="inputCol" name="categorie" (keyup.enter)="getUrlDatatable(endPOint, 'categorie_concernees.name',categorie, false)" id="">
                                  </th>
                                  <th class="text-left">
                                    Ministère
                                  </th>
                                                        
                                  <th class=" text-center" *ngIf="beneficiaireListToCheck.length != 0" >
                                    <input  #checkAll  type="checkbox" name=""  ngbTooltip="Cocher tout / Décocher tout"  (click)="cocherToutBeneficiaire(beneficiaireListToCheck,checkAll)" >
                                  </th>
                                </tr>
                              </thead>
                              <tbody *ngIf="beneficiaireListToCheck?.length != 0" >
                                  <tr *ngFor="let item of beneficiaireListToCheck">
                                    <td>{{item.matricule}}</td>
                                    <td>{{item.nom}}</td>
                                    <td>{{item.prenom}}</td>
                                    <td>{{item.telephone}}</td>
                                    <td>{{item.carte_numero_serie}}</td>
                                    <td>{{item.name}}</td>

                                      
                                      <td class="text-center" >
                                        <input #checkBeneficiaire type="checkbox" [checked]="isCheckedElmBeneficiaire" (click)="cocherBeneficiaire(item,checkBeneficiaire)" >
                                    </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="beneficiaireListToCheck?.length == 0">
                              <tr class="text-center">
                                <td colspan="6" >{{__('label_no_data')}}</td>
                              </tr>
                            </tbody> 
                            </table>
                    
                            <div class="row footer-paginate pt-2" *ngIf="beneficiaireListToCheck.length != 0">
                              <div class="col-lg-4"> 
                                <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
                              </div>
                              <div class="col-lg-8 btn-paginate"  >
                                <button *ngIf="currentPage != 1" (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                                <button *ngIf="currentPage != 1" (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                                <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
                                <span *ngIf="currentPage > 3" class="three-point" > ... </span>
                                <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
                                <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
                                <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
                                <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
                                <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
                                <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
                                <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
                                <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                                <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                              </div>
                            </div>
                            <br>
                              <div style="flex: right;" *ngIf="beneficiaireListToCheck.length != 0" >
                                <button [disabled]="tabBeneficiaireCheck.length == 0" cdkStepperNext type="button" name="button" class="btn mb-4 button-control"    >Valider</button>                      
                              </div>
                        
                            </div>








                    </div>
                    
            </cdk-step>
            
            <cdk-step  [completed]="false">
              <ng-template cdkStepLabel>
                <div class="step-bullet">2</div>
                <div class="step-title">Recapitulatif</div>
              </ng-template>
                <div class="row w-100">
                    <!-- <hr>
                        <h4 class="text-center">Recapitulatif des cartes a enrôler</h4>
                    <hr> -->
                    <div class="col-md-12" >
                        <table style="width: 100%"  class="table table-striped table-hover " >
                            <thead>
                              <tr>
                                <th>Matricule</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Téléphone</th>
                                <th>Numéro série carte</th>
                              
                              </tr>
                            </thead>
                            <tbody *ngIf="tabBeneficiaireCheck?.length != 0" >
                                <tr *ngFor="let item of tabBeneficiaireCheck">
                                  <td>{{item.matricule}}</td>
                                  <td>{{item.nom}}</td>
                                  <td>{{item.prenom}}</td>
                                  <td>{{item.telephone}}</td>
                                  <td>{{item.carte_numero_serie}}</td>
                              </tr>
                            </tbody>
                          
                          </table>
                          <hr class="mt-2">
                          <div class="row d-flex justify-content-end mt-3">
                            <button type="button" name="button" class="btn mb-4 button-control" (click)="submitEnrolementMultiple()" >Sauvegarder</button>
                          </div>
                          
                    </div>
                    
                </div>
            </cdk-step>
            
         </ng-stepper>
      </div>
  </form>
    
  </ng-template> 
