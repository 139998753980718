import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../../../../service/Translatable';
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {etablissement, ville,province, user, president, annee_scolaire, categorie} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { DomSanitizer } from '@angular/platform-browser';
import {constante} from '../../../../service/constante';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-etablissement',
  templateUrl: './etablissement.component.html',
  styleUrls: ['./etablissement.component.scss']
})
export class EtablissementComponent extends Translatable implements OnInit {
  dtOptions: DataTables.Settings = {};
  
  public etablissements :etablissement[] = [] ;
  public etablissement:etablissement = new etablissement();
  public etablissement_error : etablissement ;
  public presidents :president[] = [] ;
  public users:user [] = [];
  public provinces:province [] = [];
  public villes:ville [] = [];
  public president:president = new president();
  public president_error : president ;
  documentLogo: any = [];

  titleModal:string ;
  isAdd : boolean = true ;
  title:string ;
  public module_code = window['module'];
  public superAdmin : number ;
  etabId:any ;
  fullName: string;
  savedLogo: any = [];
  codeUniveReqiured: boolean = false;
  codeUnivMax5: boolean = false;
  public categories :categorie[] = [] ;
  public idEns : number;
   public tabCategorie = Array();
   titleModalAffect:string ;

  /*
    FORM VALIDATOR
  */
  registerForm : FormGroup;

  submitted    = false;

  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient,
            private sanitizer: DomSanitizer,
            private formBuilder: FormBuilder) { 
    super()
  }




  async ngOnInit() {
   
    this.etablissement.type_etablissement = 0;
    this.registerForm = this.formBuilder.group({
      nom:            ['', Validators.required],
      phone:          ['', Validators.required],
      email:           ['', Validators.required],
      adresse:   ['', Validators.required],
      code_postal:       ['', Validators.required],
      province:       ['', Validators.required],
      nom_ministre:        ['', Validators.required],
      prenom_ministre:    ['', Validators.required],
      logo:           [''],
      code_universite: [''],
      type_universite: ['', Validators.required]
   
    });

    this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
    this.initDatatable()
    let userinfo = this.auth.account()
    this.etabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;
    this.etablissement.type_etablissement = 0;
  }

 

  initDatatable(){
    this.isAdd = true ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "nom_ministre"
      },
      {
        "data": "prenom_ministre"
      },
      {
        "data": "email"
      },
      {
        "data": "telephone"
      },
      {
        "data": "adresse"
      },
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

      let where : any;
      if(this.superAdmin == 0 && this.etabId != 1){
         where = "where=id|e|"+this.etabId+"&";
      }else{
         where = "";
      } 
      this.http
        .get<DataTablesResponse>(
          environment.etablissement+"?"+where+ $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.etablissements = resp.data;
        console.log(this.etablissements);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 
  }
  

  async openModal(template:TemplateRef<any>,currenUser:etablissement =null,elem,otherEl) {
    this.etablissement_error = new etablissement()
    this.provinces = await this.helper.getSelectList(environment.province,['name']);

    if(currenUser == null){
      this.documentLogo = [];
      this.isAdd = true ;
      this.etablissement = new etablissement() ;
      this.etablissement.type_etablissement = 0;
      this.titleModal = this.__('etablissement.add') ;
    }else {
      this.documentLogo.name = currenUser.logo;
      this.isAdd = false ;
      this.titleModal = this.__('etablissement.edit') ;
      this.etablissement = currenUser ;
      
    } 
 
    this.modalService.open(template, { size: 'xl', backdrop:"static"  });
    
    

  } 

  async openModalDetails(template: TemplateRef<any>, currentEtab: etablissement = null, elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details');
    this.etablissement = currentEtab
    this.modalService.open(template, { size: 'xl', backdrop:"static"  });
    elm.disabled = false
  }

  // async openModalAffectation(template: TemplateRef<any>, currentEtab: etablissement = null, elm) {
  //   let resp:any = await this.http.get<any>( environment.pension_categories, valuesys.httpAuthOptions()).toPromise();
  //   this.categories = resp.data;
  //   elm.disabled = true
  //   this.titleModal = this.__('label_details');
  //   this.etablissement = currentEtab;
  //   console.log('Etablissement id', this.etablissement)
  //   let categorie = "";
  //   this.modalService.open(template, { size: 'xl', backdrop:"static"  });
  //   elm.disabled = false
  // }

  async openModalAffectation(template:TemplateRef<any>,currentEtab:etablissement =null,elem) {
    let resp:any = await this.http.get<any>( environment.pension_categories, valuesys.httpAuthOptions()).toPromise();
    this.categories = resp.data;
    //this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    let arr = await this.http.get<any>(environment.affectationCategoryEtablissement+"?page=1&where=categorie_etablissement.etablissement_id|e|" + currentEtab.id,valuesys.httpAuthOptions()).toPromise();

      function userExists(name) {
        return arr.data.some(function(el) {
          return el.categorie_concernee_id === name;
        }); 
      }

    this.tabCategorie = [];
    for (let index = 0; index < this.categories.length; index++) {
      if(userExists(this.categories[index]["id"]) == true){
        var obj = Object.assign(this.categories[index], {"selected": "checked", "idEtab":currentEtab.id});
        this.tabCategorie.push(obj);
      }else{
        var obj = Object.assign(this.categories[index], {"selected": "","idEtab":currentEtab.id});
        this.tabCategorie.push(obj);
      }
    } 

    console.log();
    this.isAdd = false ;
    this.titleModalAffect= currentEtab.name ;
    this.etablissement = currentEtab ;
    
    this.modalService.open(template, { size: 'xl', backdrop:"static"  });

  } 


  async updateCheck(idEtablissement, idCategoryEtablissement,check){

    if(check.checked==true){

      console.log(idEtablissement);
      
      let res:any =  await this.http.post<any>(environment.affectationCategoryEtablissement,{"etablissement_id":idEtablissement, "categorie_concernee_id":idCategoryEtablissement},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){ this.toastr.success(res.msg,this.__("msg.success")) ; }
        else { this.toastr.error(res.msg,this.__("msg.error")) ; }

    }else{
      let dataId = await this.http.get<any>(environment.affectationCategoryEtablissement+"?page=1&where=categorie_etablissement.etablissement_id|e|" + idEtablissement+",categorie_etablissement.categorie_concernee_id|e|"+ idCategoryEtablissement,valuesys.httpAuthOptions()).toPromise();
      console.log("dddd", dataId)
      let idEtab = dataId.data[0]['id'] //recupération Id
      
      let sup = await this.http.delete<any>(environment.affectationCategoryEtablissement+"/" + idEtab,valuesys.httpAuthOptions()).toPromise();
      console.log("dddd", idEtab)
      if(sup.code === 204){ this.toastr.success(sup.msg,this.__("msg.deleted")) ; }
        else { this.toastr.error(sup.msg,this.__("msg.error")) ; }
    }
  } 


  async toggleState(etablissement:etablissement,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.etablissement+`/${etablissement.id}/state/${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      etablissement.state = toogle.data.state
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

 
  //--fermeture du modal
  close(){
    this.modalService.dismissAll()
    this.etablissement = new etablissement()
    this.titleModal = this.__('label_user_edit') ;
    this.etablissement_error = new etablissement();
    this.codeUniveReqiured = false;
    this.codeUnivMax5 = false;
  }
  
  public findInvalidControls() {
      const invalid = [];
      const controls = this.registerForm.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      return invalid;
  }

  // GET REGISTER FORM VALIDATE
  get f() { return this.registerForm.controls; }

  async addEtabForm(){
    console.log(this.etablissement,'etab')
    console.log(this.registerForm.controls['code_universite'].value)
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    if(this.etablissement.type_etablissement == 1 && !this.etablissement.code_universite) {
      this.codeUniveReqiured = true;
      return
    }
    if(this.etablissement.type_etablissement == 1 && this.etablissement.code_universite.toString().length > 5) {
      this.codeUnivMax5 = true;
      return
    }
    //--add Logo
    this.etablissement.logo = this.documentLogo.name;
    this.etablissement_error = new etablissement()

    //--- Add etablissement
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.etablissement,this.etablissement,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.etablissements.push(res.data);
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.etablissement_error[item] = res.data[item][0] ;
          }
          this.etablissement_error = <etablissement> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update etablissement
    }else {
        console.log(this.etablissement);
        let res =  await this.http.put<any>( environment.etablissement + '/'+this.etablissement.id,this.etablissement,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.etablissement_error = <etablissement> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        } 
    }
  }

  delete(deletedUser:etablissement,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.etablissement+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.etablissements= this.etablissements.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }


  element : any ;
  currenCode :string ="mg";
   /*telephone */
   objetPhone : any ;
   onCountryChange(m){
     let lastDiall =  this.objetPhone .dialCode ;
     let newDiall = m.dialCode
     console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
      //this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
     this.objetPhone = m ;
     /*Changed*/
     this.etablissement.telephone = this.element.value
     /*Changed*/
   }
   telInputObject(m){
     this.objetPhone = m.s ;
   }

   controle(element){
      this.objetPhone.dialCode = "03"
      this.element = element ;
      element.value = element.value.replace(/[^0-9]/gi,'');
      if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
       if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
       {
         element.value =  this.objetPhone.dialCode;
       }
       else
       {
         if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
           element.value =  element.value;
         }
         else {
           element.value = this.objetPhone.dialCode +  element.value;
         }
       }
     } 
    this.etablissement.telephone = element.value;
   }
   /*telephone */



   /*IMAGE ADD*/
  // sanitizer:DomSanitizer =DomSanitizer.;
  getUrl(url:string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

   addImage(documentFile: HTMLInputElement) {
    documentFile.click()
  }


  handleInputLogo(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }
    //this.etablissement.logo = files[0].name;

    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
      var pattern = /image-*/;

      if (!files[i].type.match(pattern)) {
        this.toastr.error("invalid format", "Error");
        return;
      }
      reader.onload = this._handleReaderLogoLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }

  }

  _handleReaderLogoLoaded(e) {
    let reader = e.target;
    this.documentLogo.name = reader.result;
  }


  download(document) {
      console.log("document",document)
      const docSplit = document.name.split("/")
      const extensionsTAb = docSplit[docSplit.length - 1].split(".");
      this.helper.dowloadFile(document.name,extensionsTAb[0],extensionsTAb[1]);
  }
  async exportEtablissementToExcel() {

    this.helper.exportExcel(this.printPaiement(this.etablissements),'Listes établissements').then(
      (response: any)=>{
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `listes_etablissements.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  } 
  printPaiement(etablissements:any[]){
    return  etablissements.map((e)=>{
      let t = {}
      t['Code'] = e.code
      t[this.__('etablissement.name')] = e.name
      t[this.__('etablissement.typ_etablissment')] = e.type_etablissement == 0 ?  "Autres" : "Université"
      t[this.__('etablissement.code_univ')] = e.code_universite
      t[this.__('etablissement.email')] = e.email
      t[this.__('etablissement.telephone')] = e.telephone
      return t ;
    })  || [];

  }
  async exportEtablissementToPdf() {
    await this.helper.exportPdf(this.printPaiement(this.etablissements),'Listes établissements','','listes_etablissements');
  }



}