import { Component, OnInit } from '@angular/core';
import { Event,Router,NavigationStart ,NavigationEnd } from '@angular/router';
import { LoaderService } from './administration/service/loader.service';
import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls:['./app.component.css'],
  providers: [IconSetService],
})
export class AppComponent implements OnInit {

  showLoadingIndicator = false;

  constructor(
    private router: Router,
    public iconSet: IconSetService,
    public loaderService: LoaderService,
    public spinner: NgxSpinnerService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
    
  }

  ngOnInit() {
    this.spinner.show();
    console.log = function () {}

  }
}
